import React, { createContext, useCallback, useState } from 'react';

interface IModalContextProps {
  openModal: (_modal: React.ReactNode) => void;
  closeModal: () => void;
}

export const ModalContext = createContext<IModalContextProps>({
  openModal: () => {},
  closeModal: () => {},
});

interface IModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: IModalProviderProps) => {
  const [modal, setModal] = useState<React.ReactNode>(null);

  const openModal = useCallback((modal: React.ReactNode) => {
    setModal(modal);
  }, []);

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="absolute inset-0 bg-black bg-opacity-25"
            onClick={closeModal}
          />
          {modal}
        </div>
      )}
    </ModalContext.Provider>
  );
};
