import React, { useRef } from 'react';
import { upload } from '../../assets';

const VisualsUploaderCard: React.FC<{
  propertyLogo: File | null;
  mainPropertyImage: File | null;
  errors: Record<string, string>;
  handleDrop: (
    event: React.DragEvent<HTMLDivElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => void;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => void;
  setPropertyLogo: React.Dispatch<React.SetStateAction<File | null>>;
  setMainPropertyImage: React.Dispatch<React.SetStateAction<File | null>>;
}> = ({
  propertyLogo,
  mainPropertyImage,
  errors,
  handleDrop,
  handleFileChange,
  setPropertyLogo,
  setMainPropertyImage,
}) => {
  const propertyLogoInputRef = useRef<HTMLInputElement>(null);
  const mainPropertyImageInputRef = useRef<HTMLInputElement>(null);

  const handleAreaClick = (inputRef: React.RefObject<HTMLInputElement>) => {
    inputRef.current?.click();
  };

  return (
    <section className="flex flex-col px-6 py-4 mt-6 w-full bg-white rounded-md border border-[#CBD5E1]">
      <header className="w-full text-sm font-semibold text-black">
        <h2 className="text-lg">Visuals</h2>
        <hr className="mt-4 w-full border-slate-200" aria-hidden="true" />
      </header>
      <div className="flex flex-col mt-8 w-full">
        <div className="flex flex-col w-full">
          <label className="text-sm font-medium text-black">
            Property logo
          </label>
          <div
            className={`flex flex-col justify-center items-center px-3 py-6 mt-1.5 w-full bg-white rounded-md border ${
              errors.propertyLogoUrl ? 'border-red-500' : 'border-[#CBD5E1]'
            } cursor-pointer hover:bg-gray-50 transition-colors duration-200`}
            onDrop={(e) => handleDrop(e, setPropertyLogo)}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => handleAreaClick(propertyLogoInputRef)}
          >
            <input
              ref={propertyLogoInputRef}
              type="file"
              accept="image/png, image/jpeg"
              className="hidden"
              onChange={(e) => handleFileChange(e, setPropertyLogo)}
            />
            <div className="flex flex-col items-center w-[206px] max-w-full">
              {propertyLogo ? (
                <img
                  src={URL.createObjectURL(propertyLogo)}
                  alt="Uploaded property logo preview"
                  className="object-contain w-full aspect-square"
                />
              ) : (
                <img
                  loading="lazy"
                  src={upload}
                  className="object-contain w-6 aspect-square"
                  alt="Default placeholder"
                />
              )}
              <div className="flex gap-1 justify-center items-center w-full text-base font-medium mt-2 whitespace-nowrap">
                <span>Drag image here or browse</span>
              </div>
              <p className="text-xs text-center text-[#334155] mt-1">
                PNG or JPG (max. 512x512px)
              </p>
            </div>
          </div>
          {errors.propertyLogoUrl && (
            <p className="text-red-500 text-xs mt-1">
              {errors.propertyLogoUrl}
            </p>
          )}
        </div>
        <div className="flex flex-col w-full mt-4">
          <label className="text-sm font-medium text-black">
            Main property image
          </label>
          <div
            className={`flex flex-col justify-center items-center px-3 py-6 mt-1.5 w-full bg-white rounded-md border ${
              errors.mainPropertyImageUrl
                ? 'border-red-500'
                : 'border-[#CBD5E1]'
            } cursor-pointer hover:bg-gray-50 transition-colors duration-200`}
            onDrop={(e) => handleDrop(e, setMainPropertyImage)}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => handleAreaClick(mainPropertyImageInputRef)}
          >
            <input
              ref={mainPropertyImageInputRef}
              type="file"
              accept="image/png, image/jpeg"
              className="hidden"
              onChange={(e) => handleFileChange(e, setMainPropertyImage)}
            />
            <div className="flex flex-col items-center w-[206px] max-w-full">
              {mainPropertyImage ? (
                <img
                  src={URL.createObjectURL(mainPropertyImage)}
                  alt="Uploaded main property preview"
                  className="object-contain w-full aspect-square"
                />
              ) : (
                <img
                  loading="lazy"
                  src={upload}
                  className="object-contain w-6 aspect-square"
                  alt="Default placeholder"
                />
              )}
              <div className="flex gap-1 justify-center items-center w-full text-base font-medium text-slate-900 mt-2 whitespace-nowrap">
                <span>Drag image here or browse</span>
              </div>
              <p className="text-xs text-center text-[#334155] mt-1">
                PNG or JPG (max. 512x512px)
              </p>
            </div>
          </div>
          {errors.mainPropertyImageUrl && (
            <p className="text-red-500 text-xs mt-1">
              {errors.mainPropertyImageUrl}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default VisualsUploaderCard;
