import NetworkDropdown from './NetworkDropdown';
import { NetworkProps, networks } from './NetworkDropdown';

interface DetailItemProps {
  type: 'text' | 'number';
  mode: 'text' | 'decimal';
  label: string;
  value: number | string;
  onChange?: Function;
}

const DetailItem: React.FC<DetailItemProps> = ({
  type,
  mode,
  label,
  value,
  onChange,
}) => (
  <div className="flex justify-between pt-1.5 pb-2 mt-2.5 border-b border-solid border-zinc-800">
    <label htmlFor={label} className="my-auto text-neutral-400">
      {label}
    </label>
    <input
      id={label}
      type={type}
      inputMode={mode}
      className="w-[120px] justify-center items-start px-2.5 py-2 text-white whitespace-nowrap rounded-xl border border-solid bg-zinc-900 border-neutral-700"
      value={value}
      onChange={(e) => {
        type === 'text'
          ? onChange?.(e.target.value)
          : onChange?.(Number(e.target.value));
      }}
    />
  </div>
);

interface EditProjectCardProps {
  project: any;
  selectedNetwork: NetworkProps | undefined;
  setSelectedNetwork: Function;
  raisingIn: any;
  setRaisingIn: Function;
  pricePerToken: any;
  setPricePerToken: Function;
  maxmimumInvestment: any;
  setMaxmimumInvestment: Function;
  totalRaiseGoal: any;
  setTotalRaiseGoal: Function;
}

const EditProjectCard: React.FC<EditProjectCardProps> = ({
  project,
  selectedNetwork,
  setSelectedNetwork,
  raisingIn,
  setRaisingIn,
  pricePerToken,
  setPricePerToken,
  maxmimumInvestment,
  setMaxmimumInvestment,
  totalRaiseGoal,
  setTotalRaiseGoal,
}) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="w-full flex flex-col p-5 bg-black rounded-3xl backdrop-blur-[50px] max-w-[357px]">
        <header>
          <h2 className="justify-center text-base font-semibold text-white whitespace-nowrap">
            Details
          </h2>
        </header>
        <div className="flex flex-col mt-5 text-sm">
          <div className="flex gap-4 justify-between pt-1.5 pb-2 whitespace-nowrap border-b border-solid border-zinc-800">
            <div className="text-neutral-400">Participants</div>
            <div className="self-start text-white">698</div>
          </div>
          <div className="flex justify-between pt-1.5 pb-2 mt-2.5 border-b border-solid border-zinc-800">
            <label
              htmlFor={'Project built on'}
              className="my-auto text-neutral-400"
            >
              {'Project built on'}
            </label>
            <NetworkDropdown
              selectedNetwork={selectedNetwork}
              setSelectedNetwork={setSelectedNetwork}
              networks={networks}
            />
          </div>
          <DetailItem
            type="text"
            mode="text"
            label="Raising in"
            value={raisingIn}
            onChange={setRaisingIn}
          />
          <DetailItem
            type="number"
            mode="decimal"
            label="Price per unit"
            value={pricePerToken}
            onChange={setPricePerToken}
          />
          <DetailItem
            type="number"
            mode="decimal"
            label="Maxmimum investment"
            value={maxmimumInvestment}
            onChange={setMaxmimumInvestment}
          />
          <DetailItem
            type="number"
            mode="decimal"
            label="Total raise goal"
            value={totalRaiseGoal}
            onChange={setTotalRaiseGoal}
          />
        </div>
      </div>
    </div>
  );
};

export default EditProjectCard;
