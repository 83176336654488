import useRestApi from './useRestApi';
import useToken from './useToken';
import useAppSettings from './useAppSettings';

export const FANTOM_STARTER_ADMIN_API_URL =
  'https://raven.futurestarter.xyz/fs-admin/api';
export const FANTOM_STARTER_PROD_ADMIN_API_URL =
  'https://phoenix.futurestarter.xyz/fs-admin/api';

export enum FANTOM_STARTER_ADMIN_METHODS {
  LOGIN = '/login/admin',
  CREATE_PROJECT = '/admin/project/create',
  UPDATE_PROJECT_DETAILS = '/admin/project/update-project-details',
  DELETE_PROJECT = '/admin/project/delete',
  SAVE_PROJECT_SUMMARY = '/admin/project/save-summary',
  DEPLOY_PROJECT_CONTRACTS_INO = '/admin/project/deploy-contracts/ino',
  DEPLOY_PROJECT_CONTRACTS_ICO = '/admin/project/deploy-contracts/ico',
}

export enum FANTOM_STARTER_METHODS {
  ALL_PROJECTS = '/projects/all',
  ALL_PROJECTS_FOR_OWNER = '/projects/all/owner',
  PROJECT = '/projects',
  GET_USER = '/login/user',
  REGISTER = '/login/register',
}

export enum FANTOM_STARTER_CIRCLE_METHODS {
  LINK_WIRE_BANK_ACCOUNT = '/circle/wire/bank/link',
  CREATE_PROJECT_FUND_DEPOSIT = '/circle/deposit/project',
  INITIATE_WITHDRAW_TO_BANK = '/circle/wire/withdraw/project',
  GET_WIRE_BANKS = '/circle/wire/banks',
  GET_WIRE_BANK_DESCRIPTION = '/circle/wire/bank/description',
  GET_DEPOSIT_FOR_PROJECT_ID = '/circle/deposit/project',
  EXISTS_PAYOUT_FOR_PROJECT_ID = '/circle/wire/withdraw/project/exists',
}
export interface IAddress {
  name: string;
  city: string;
  country: string;
  line1: string;
  line2?: string;
  district: string;
  postalCode: string;
}

export interface IBankAddress extends Omit<IAddress, 'name' | 'postalCode'> {
  bankName: string;
}

export interface IBankAccount {
  idempotencyKey: string;
  accountNumber: string;
  routingNumber: string;
  billingDetails: IAddress;
  bankAddress: IBankAddress;
}

export interface IBankAccountWithIban {
  idempotencyKey: string;
  iban: string;
  billingDetails: IAddress;
  bankAddress: IBankAddress;
}

const useFantomStarterAdminApi = () => {
  const { get, post } = useRestApi(FANTOM_STARTER_ADMIN_API_URL);
  const { get: getProd, post: postProd } = useRestApi(
    FANTOM_STARTER_PROD_ADMIN_API_URL
  );
  const { token } = useToken();
  const { isProd } = useAppSettings();

  const login = (account: string, signature: string, timestamp: number) => {
    return post({
      path: FANTOM_STARTER_ADMIN_METHODS.LOGIN,
      body: {
        address: account,
        signature,
        timestamp,
      },
    });
  };

  const getAllProjects = () => {
    return isProd
      ? getProd({
          path: FANTOM_STARTER_METHODS.ALL_PROJECTS,
          queryParams: [['env', isProd ? 'prod' : 'test']],
        })
      : get({
          path: FANTOM_STARTER_METHODS.ALL_PROJECTS,
          queryParams: [['env', isProd ? 'prod' : 'test']],
        });
  };

  const getAllProjectsForOwner = (userId: string) => {
    return isProd
      ? getProd({
          path: FANTOM_STARTER_METHODS.ALL_PROJECTS_FOR_OWNER,
          params: [userId],
          queryParams: [['env', isProd ? 'prod' : 'test']],
        })
      : get({
          path: FANTOM_STARTER_METHODS.ALL_PROJECTS_FOR_OWNER,
          params: [userId],
          queryParams: [['env', isProd ? 'prod' : 'test']],
        });
  };

  const getProject = (projectId: string) => {
    return get({
      path: FANTOM_STARTER_METHODS.PROJECT,
      params: [projectId],
      queryParams: [['env', isProd ? 'prod' : 'test']],
    });
  };

  const getUser = (address: string) => {
    return get({
      path: FANTOM_STARTER_METHODS.GET_USER,
      params: [address],
    });
  };

  const registerUser = (address: string) => {
    return get({
      path: FANTOM_STARTER_METHODS.REGISTER,
      params: [address],
    });
  };

  const createProject = (project: any) => {
    return post({
      path: FANTOM_STARTER_ADMIN_METHODS.CREATE_PROJECT,
      body: { data: project },
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  const updateProjectDetails = (details: any) => {
    return post({
      path: FANTOM_STARTER_ADMIN_METHODS.UPDATE_PROJECT_DETAILS,
      body: { data: details },
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  const deleteProject = (projectId: string) => {
    return post({
      path: FANTOM_STARTER_ADMIN_METHODS.DELETE_PROJECT,
      body: { data: { id: projectId } },
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };
  const migrateToProduction = async (project: any) => {
    const testToProd = (chainId: string) => {
      if (chainId === '4002') return '250';
      if (chainId === '97') return '56';
      if (chainId === '43113') return '43114';
      if (chainId === '80002') return '137';
      if (chainId === '3441005') return '169';
      if (chainId === '168587773') return '81457 ';
      if (chainId === '919') return '34443';
    };
    project.chain = testToProd(project.chain);

    const prodProject = await postProd({
      path: FANTOM_STARTER_ADMIN_METHODS.CREATE_PROJECT,
      body: { data: project },
      queryParams: [['env', 'prod']],
      token,
    });
    const projectData = await getProject(project.id);

    return postProd({
      path: FANTOM_STARTER_ADMIN_METHODS.SAVE_PROJECT_SUMMARY,
      body: {
        data: {
          id: prodProject.data.result.data.projectId,
          summary: projectData.data.result.campaign,
        },
      },
      queryParams: [['env', 'prod']],
      token,
    });
  };

  const saveProjectSummary = (projectId: string, summary: any) => {
    return post({
      path: FANTOM_STARTER_ADMIN_METHODS.SAVE_PROJECT_SUMMARY,
      body: { data: { id: projectId, summary } },
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  const deployContractsForProject = (
    deployConfig: any,
    type: 'ino' | 'ico'
  ) => {
    if (type === 'ino') {
      return post({
        path: FANTOM_STARTER_ADMIN_METHODS.DEPLOY_PROJECT_CONTRACTS_INO,
        body: { data: deployConfig },
        queryParams: [['env', isProd ? 'prod' : 'test']],
        token,
      });
    }
    if (type === 'ico') {
      return post({
        path: FANTOM_STARTER_ADMIN_METHODS.DEPLOY_PROJECT_CONTRACTS_ICO,
        body: { data: deployConfig },
        queryParams: [['env', isProd ? 'prod' : 'test']],
        token,
      });
    }
  };

  const getWireBanks = () => {
    return get({
      path: FANTOM_STARTER_CIRCLE_METHODS.GET_WIRE_BANKS,
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  const getWireBankDescription = (wireId: string) => {
    return get({
      path: FANTOM_STARTER_CIRCLE_METHODS.GET_WIRE_BANK_DESCRIPTION,
      params: [wireId],
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  const linkWireBankAccount = (
    bankAccount: IBankAccount | IBankAccountWithIban
  ) => {
    return post({
      path: FANTOM_STARTER_CIRCLE_METHODS.LINK_WIRE_BANK_ACCOUNT,
      body: bankAccount,
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  const createProjectFundDeposit = (projectId: string, txHash: string) => {
    return post({
      path: FANTOM_STARTER_CIRCLE_METHODS.CREATE_PROJECT_FUND_DEPOSIT,
      body: { txHash },
      params: [projectId],
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  const initiateWithdrawToBank = (
    projectId: string,
    idempotencyKey: string
  ) => {
    return post({
      path: FANTOM_STARTER_CIRCLE_METHODS.INITIATE_WITHDRAW_TO_BANK,
      body: { idempotencyKey },
      params: [projectId],
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  const getDepositForProjectId = (projectId: string) => {
    return get({
      path: FANTOM_STARTER_CIRCLE_METHODS.GET_DEPOSIT_FOR_PROJECT_ID,
      params: [projectId],
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  const existsPayoutForProjectId = (projectId: string) => {
    return get({
      path: FANTOM_STARTER_CIRCLE_METHODS.EXISTS_PAYOUT_FOR_PROJECT_ID,
      params: [projectId],
      queryParams: [['env', isProd ? 'prod' : 'test']],
      token,
    });
  };

  return {
    admin: {
      login,
      createProject,
      updateProjectDetails,
      deleteProject,
      deployContractsForProject,
      saveProjectSummary,
      migrateToProduction,
    },
    circle: {
      getWireBanks,
      getWireBankDescription,
      linkWireBankAccount,
      createProjectFundDeposit,
      initiateWithdrawToBank,
      getDepositForProjectId,
      existsPayoutForProjectId,
    },
    getAllProjects,
    getAllProjectsForOwner,
    getProject,
    getUser,
    registerUser,
  };
};

export default useFantomStarterAdminApi;
