import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

interface IDesktopLayoutProps {
  children: React.ReactNode;
}

const DesktopLayout: React.FC<IDesktopLayoutProps> = ({ children }) => {
  return (
    <>
      <div className="overflow-y-hidden sm:overflow-visible bg-white">
        <div className="sm:bg-white">
          <Navbar />
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default DesktopLayout;
