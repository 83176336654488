import { useEffect, useRef, useState } from 'react';
import { DesktopLayout } from '../../components/Layouts';
import { Table } from '../../components/Table';
import { Pagination } from '../../components/Pagination';
import {
  search,
  repeat,
  archive,
  downlaod,
  circleSlashed,
  trash,
  unchecked,
  arrowUpDown,
} from '../../assets';

export default function Contracts() {
  const [tabs, setTabs] = useState([
    { label: 'Active', isActive: true },
    { label: 'Archived', isActive: false },
    { label: 'Voided', isActive: false },
    { label: 'Deleted', isActive: false },
  ]);

  const iconButtons = [
    { src: repeat, alt: 'repeat' },
    { src: archive, alt: 'archive' },
    { src: downlaod, alt: 'download' },
    { src: circleSlashed, alt: 'circle-slashed' },
    { src: trash, alt: 'trash' },
  ];

  const headers = [
    'Contract Date',
    'Sale ID',
    'Owner',
    'Last Updated',
    'Status',
    'Sale Status',
  ];

  const [selectedSection, setSelectedSection] = useState<string>('Owner');
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [columnWidth, setColumnWidth] = useState<number>(0);

  const toggleSection = (section: string) => {
    setSelectedSection(selectedSection === section ? selectedSection : section);
  };

  const handleTabClick = (index: number) => {
    const updatedTabs = tabs.map((tab, i) => ({
      ...tab,
      isActive: i === index,
    }));
    setTabs(updatedTabs);
  };

  useEffect(() => {
    if (headerRef.current) {
      const width = headerRef.current.getBoundingClientRect().width;
      setColumnWidth(width);
    }
  }, []);

  return (
    <DesktopLayout>
      <main className="pt-24 sm:px-32 px-5 flex flex-col items-start">
        <header className="flex flex-col px-4 pt-4 pb-6 bg-white">
          <h1 className="text-xl text-slate-900 max-md:max-w-full">
            Sales Contract Management
          </h1>
          <p className="text-sm text-neutral-600 max-md:max-w-full">
            Track and manage the status of sales contracts, with sortable and
            filterable fields for easy pipeline management.
          </p>
        </header>

        <nav className="flex py-1.5 px-1.5 rounded-md bg-slate-100 text-slate-700">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={`px-3 py-1.5 rounded ${
                tab.isActive ? 'bg-white text-slate-900' : 'text-slate-700'
              }`}
            >
              {tab.label}
            </button>
          ))}
        </nav>

        <nav className="flex items-center justify-end gap-4 py-2 bg-white w-full">
          <div className="flex items-center gap-2 px-2 py-2.5 w-[280px] text-sm leading-none border rounded-md shadow-sm">
            <img
              loading="lazy"
              src={search}
              alt=""
              className="w-4 h-4 object-contain"
            />
            <input
              type="text"
              placeholder="Search a sale ID"
              className="flex-1 text-slate-400 bg-transparent border-none outline-none placeholder-opacity-50"
              aria-label="Search a sale ID"
            />
          </div>

          {iconButtons.map((button, index) => (
            <button
              key={index}
              className="flex justify-center items-center w-10 h-10 bg-white border rounded-md"
            >
              <img
                loading="lazy"
                src={button.src}
                alt={button.alt}
                className="w-4 h-4 object-contain"
              />
            </button>
          ))}

          <button className="btn-primary">Start a sale</button>
        </nav>
        <section className="w-full overflow-x-auto">
          <div className="grid grid-cols-[56px_repeat(6,1fr)] gap-0 bg-slate-50 w-full text-sm font-semibold text-slate-900 whitespace-nowrap">
            <div className="flex items-center justify-center">
              <img
                loading="lazy"
                src={unchecked}
                className="w-4 aspect-square mx-auto"
                alt="check-box"
              />
            </div>

            {headers.map((header, index) => (
              <div
                key={index}
                ref={index === 0 ? headerRef : null}
                onClick={() => toggleSection(header)}
                className={`p-4 cursor-pointer flex justify-between items-center
                ${selectedSection === header ? 'bg-slate-300 text-black' : 'bg-slate-50'}`}
              >
                {header}
                {selectedSection === header && (
                  <img
                    src={arrowUpDown}
                    alt="Arrow"
                    className="w-6 h-6 object-contain ml-2"
                  />
                )}
              </div>
            ))}
          </div>

          <Table columnWidth={columnWidth} />
          <Pagination />
        </section>
      </main>
    </DesktopLayout>
  );
}
