import React, { useState, useRef, useCallback } from 'react';
import { cross, plusBlack, checked, unchecked } from '../../assets';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import { toast } from 'react-toastify';

interface EditModalProps {
  onClose: () => void;
  project: any;
}

const EditModal: React.FC<EditModalProps> = ({ onClose, project }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const defaultLiquidityFactor = 0.5;

  const {
    admin: { updateProjectDetails },
  } = useFantomStarterAdminApi();

  const uploadPreset =
    process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET ?? 'default_upload_preset';
  const cloudName =
    process.env.REACT_APP_CLOUDINARY_CLOUD_NAME ?? 'default_cloud_name';

  const [projectName, setProjectName] = useState<string>(project.name);
  const [pricePerToken, setPricePerToken] = useState<number>(
    project.sale_version === 8 || project.sale_version === 10
      ? Number(project.ratio_paytoken_per_idotoken) * defaultLiquidityFactor
      : Number(project.ratio_paytoken_per_idotoken)
  );
  const [maxInvestment, setMaxInvestment] = useState<number>(
    Number(project.cap_individual_dollars)
  );
  const [totalRaiseGoal, setTotalRaiseGoal] = useState<number>(
    Number(project.cap_total_dollars)
  );
  const [logoUrl, setLogoUrl] = useState(project.logo_image_url);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [tags, setTags] = useState<string[]>(project.tags || []);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleStringInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setter(e.target.value);

  const handleNumberInputChange =
    (setter: React.Dispatch<React.SetStateAction<number>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setter(value === '' ? 0 : Number(value));
    };

  const handleAddTag = (e: React.MouseEvent) => {
    e.preventDefault();
    const newTag = prompt('Enter a new tag:');
    if (newTag && !tags.includes(newTag)) {
      setTags((prev) => [...prev, newTag]);
    } else if (newTag) {
      alert('Tag already exists');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleLogoClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setLogoFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setLogoUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const hasChanges = () => {
    return (
      projectName !== project.name ||
      pricePerToken !==
        (project.sale_version === 8 || project.sale_version === 10
          ? Number(project.ratio_paytoken_per_idotoken) * defaultLiquidityFactor
          : Number(project.ratio_paytoken_per_idotoken)) ||
      maxInvestment !== Number(project.cap_individual_dollars) ||
      totalRaiseGoal !== Number(project.cap_total_dollars) ||
      logoUrl !== project.logo_image_url ||
      tags.join(',') !== project.tags.join(',')
    );
  };

  const handleSave = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (!isConfirmed) {
        alert(
          'Please confirm that all sale details are correct before saving.'
        );
        return;
      }

      if (!hasChanges()) {
        alert('No changes made to save.');
        return;
      }

      setIsSaving(true);
      let logoImageUrl = logoUrl;

      if (logoFile) {
        const data = new FormData();
        data.append('file', logoFile);
        data.append('upload_preset', uploadPreset);
        data.append('cloud_name', cloudName);
        data.append('folder', 'Cloudinary-React');

        try {
          const response = await fetch(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            {
              method: 'POST',
              body: data,
            }
          );
          const jsonData = await response.json();
          logoImageUrl = await jsonData.secure_url;
        } catch (error) {
          console.error('Error uploading image: ', error);
          setIsSaving(false);
          return;
        }
      }

      const response = await updateProjectDetails({
        id: project.id,
        project_id: project.id,
        name: projectName,
        tags,
        logo_image_url: logoImageUrl,
        chain: 80002,
        pay_token_symbol: 'USDC',
        cap_total_dollars: totalRaiseGoal,
        cap_individual_dollars: maxInvestment,
        ratio_paytoken_per_idotoken:
          project.sale_version === 8 || project.sale_version === 10
            ? pricePerToken / defaultLiquidityFactor
            : pricePerToken,
      });

      setIsSaving(false);

      if (response.status === 200) {
        toast.success('Updated Project Details!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        onClose();
        localStorage.setItem(`editStepDone-${project.id}`, 'true');
      } else {
        toast.error('Failed to update details!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectName,
      tags,
      logoUrl,
      maxInvestment,
      totalRaiseGoal,
      pricePerToken,
      project.id,
      project.logo_image_url,
      project.sale_version,
      updateProjectDetails,
      isConfirmed,
      logoFile,
      uploadPreset,
      cloudName,
    ]
  );

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <div>
        <div className="flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto">
          <div className="relative w-[600px] my-6 mx-auto">
            <section className="flex flex-col p-6 bg-white rounded-md shadow-sm max-w-[600px]">
              <header className="w-full">
                <div className="flex justify-center items-center text-2xl font-semibold text-slate-900">
                  <h1 className="flex-1 font-semibold">Edit Sale </h1>
                  <img
                    src={cross}
                    className="w-6 cursor-pointer"
                    alt="cross"
                    onClick={() => onClose()}
                  />
                </div>
                <p className="mt-2 text-base text-slate-600 font-normal">
                  Information will be used to deploy the smart contracts
                </p>
              </header>
              <form className="flex flex-col mt-6" onSubmit={handleSave}>
                <div className="relative flex items-center">
                  <div className="flex-1 text-sm font-medium text-slate-900">
                    Project logo
                  </div>
                  <div
                    className="relative w-14 h-14 cursor-pointer"
                    onClick={handleLogoClick}
                  >
                    <img
                      src={logoUrl}
                      className="w-14 h-14 rounded-xl object-cover hover:opacity-70"
                      alt="Project logo"
                    />
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100">
                      <div className="w-full h-full bg-black opacity-30 rounded-xl" />
                      <div className="w-6 h-6 bg-white rounded-full absolute flex justify-center items-center">
                        <img
                          src={cross}
                          className="w-3 h-3"
                          alt="Change logo"
                        />
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="flex items-center mt-4">
                  <label className="flex-1 text-sm font-medium text-slate-900">
                    Project name
                  </label>
                  <input
                    type="text"
                    value={projectName}
                    onChange={handleStringInputChange(setProjectName)}
                    className="flex-1 py-2 px-3 max-w-[272px] bg-white rounded-md border border-slate-300 outline-none"
                    aria-label="Project name"
                  />
                </div>
                <div className="flex items-center mt-4">
                  <div className="flex-1 text-sm font-medium text-slate-900">
                    Project tags
                  </div>
                  <div className="flex gap-2 flex-wrap items-center">
                    {tags.length === 0 ? (
                      <span className="text-slate-500">No tags added</span>
                    ) : (
                      tags.map((tag) => (
                        <span
                          key={tag}
                          className="px-2 py-1 bg-slate-100 rounded-md text-sm text-slate-700 flex items-center"
                        >
                          {tag}
                          <button
                            type="button"
                            onClick={() => handleRemoveTag(tag)}
                            className="ml-2 text-slate-500 hover:text-slate-700"
                            aria-label={`Remove tag ${tag}`}
                          >
                            ×
                          </button>
                        </span>
                      ))
                    )}
                    <button
                      type="button"
                      onClick={handleAddTag}
                      className="w-8 h-8 bg-white rounded-md border border-slate-200 flex items-center justify-center"
                      aria-label="Add tag"
                    >
                      <img src={plusBlack} className="w-4" alt="Add tag" />
                    </button>
                  </div>
                </div>
                {[
                  {
                    label: 'Price per unit',
                    value: pricePerToken,
                    setter: setPricePerToken,
                  },
                  {
                    label: 'Maximum investment',
                    value: maxInvestment,
                    setter: setMaxInvestment,
                  },
                  {
                    label: 'Total raise goal',
                    value: totalRaiseGoal,
                    setter: setTotalRaiseGoal,
                  },
                ].map(({ label, value, setter }) => (
                  <div key={label} className="flex items-center mt-6">
                    <div className="flex-1 text-sm font-medium text-black">
                      {label}
                    </div>
                    <input
                      type="number"
                      value={value}
                      onChange={handleNumberInputChange(setter)}
                      className="py-2 px-3 min-w-[272px] bg-white rounded-md border border-slate-300 outline-none"
                      aria-label={label}
                    />
                  </div>
                ))}
                <div className="flex items-center mt-6">
                  <img
                    src={isConfirmed ? checked : unchecked}
                    alt={isConfirmed ? 'Checked' : 'Unchecked'}
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => setIsConfirmed(!isConfirmed)}
                  />
                  <label
                    htmlFor="confirmationCheckbox"
                    className="ml-2 text-sm font-medium text-black cursor-pointer"
                    onClick={() => setIsConfirmed(!isConfirmed)}
                  >
                    Check this box to confirm that all sale details are correct.
                  </label>
                </div>
                <div className="flex justify-between mt-6 border-t-[1px] pt-4">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="btn-text w-1/2"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`btn-primary w-1/2 ${
                      !isConfirmed && 'opacity-50'
                    }`}
                    disabled={isSaving || !isConfirmed}
                  >
                    {isSaving ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
        <div
          ref={modalRef}
          className="fixed inset-0 z-40 bg-black bg-opacity-70 backdrop-blur-[2px]"
        />
      </div>
    </>
  );
};

export default EditModal;
