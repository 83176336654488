import React, { useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import calendar from '../../icons/calendar.svg';
import useWeb3Provider from '../../hooks/useWeb3Provider';
import useFantomStarterSale from '../../hooks/useFantomStarterSale';
import { weiToUnit } from '../../utils/conversion';
import Chain from '../../assets/img/Layer_1.svg';
import Flag from '../../assets/img/flag.svg';
import Sale from '../../assets/img/shop-remove.svg';
import Barcode from '../../assets/img/barcode.svg';
import Calender from '../../assets/img/calendar.svg';
import Target from '../../assets/img/Target.svg';
import Truck from '../../assets/img/truck-tick.svg';
import Code from '../../assets/img/code-circle.svg';
import Edit from '../../assets/img/edit-2.svg';
import useERC20Method from '../../hooks/useERC20ContractMethod';
import useERC1155Method from '../../hooks/useErc1155ContractMethod';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import { erc1155Abi } from '../../hooks/useErc1155ContractMethod';
import { toast } from 'react-toastify';
import {
  useSwitchNetwork,
  usePublicClient,
  useWalletClient,
  erc20ABI,
} from 'wagmi';
import { parseUnits, formatUnits } from 'viem';
import fsSaleV4 from '../../abis/fsSaleV4.json';
import fsSaleV4Ino from '../../abis/fsSaleV4Ino.json';
import fsSaleWithLiquidityProviderABI from '../../abis/fsSaleWithLiquidityProviderABI.json';
import useDocusignApi from '../../hooks/useDocusign';

type SalesCardProps = {
  project: any;
  templateHash: { [templateName: string]: any };
  chain?: string;
  id?: string;
  saleVersion?: number;
  kyc?: boolean;
  projectName?: string;
  image?: string;
  end_date_funding?: string;
  start_date_funding?: string;
  total_raised?: number;
  projectType?: string;
  wishlistStartDate?: string;
  wishlistEndDate?: string;
  saleContractAddress?: string;
  idoTokenAddress?: string;
  payTokenAddress?: string;
  capTotal?: number;
  selectedPrjectSummary?: (id: string) => void;
  selectedProject?: (id: string) => void;
  handleEdit?: (id: string) => void;
};

const SalesCard: React.FC<SalesCardProps> = ({
  project,
  templateHash,
  chain,
  id = '0',
  saleVersion,
  kyc,
  projectName,
  image,
  end_date_funding,
  start_date_funding,
  total_raised,
  projectType,
  wishlistStartDate,
  wishlistEndDate,
  saleContractAddress,
  idoTokenAddress,
  payTokenAddress,
  capTotal,
  selectedPrjectSummary,
  selectedProject,
  handleEdit,
}) => {
  const { getTemplateEditViewUrl, createTemplate, getTemplateName } =
    useDocusignApi();

  const templateName = getTemplateName(`${projectName} template`);
  const template = templateHash[templateName];

  console.log('template', template);

  const isINO = project.sale_version === 3 || project.sale_version === 5;
  const { provider, signer } = useWeb3Provider();
  const { getDecimals, getAllowance, getBalanceOf } = useERC20Method(
    Number(chain)
  );
  const { getBalanceOf: getBalanceOf1155, isApprovedForAll } = useERC1155Method(
    Number(chain)
  );
  const { saleContract, networkChangeRequired } = useFantomStarterSale({
    chainId: Number(chain),
    saleAddress: saleContractAddress,
    provider,
    signer,
    isINO,
  });
  const {
    admin: { deleteProject },
  } = useFantomStarterAdminApi();
  const { switchNetworkAsync } = useSwitchNetwork();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const [userAddress, setUserAddress] = useState<string | null>(null);
  const [idoTokenDecimals, setIdoTokenDecimals] = useState<number>(18);
  const [payTokenDecimals, setPayTokenDecimals] = useState<number>(6);
  const [isContractFunded, setIsContractFunded] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [hasEnoughBalance, setHasEnoughBalance] = useState(false);
  const [contractBalance, setContractBalance] = useState<number>(0);
  const [claimPhaseEnabled, setClaimPhaseEnabled] = useState(false);
  const [claimingStartDate, setClaimingStartDate] = useState<string>('');
  const [claimingEndDate, setClaimingEndDate] = useState<string>('');
  const [cliffVesting, setCliffVesting] = useState<boolean>(false);
  const [initialPercentageAllocation, setInitialPercentageAllocation] =
    useState<number | undefined>(undefined);
  const [isFundRetrieved, setIsFundRetrieved] = useState(false);

  const isFundingCompleted = Date.now() > new Date(end_date_funding!).getTime();

  const [isApproving, setIsApproving] = useState(false);
  const [isFundingContract, setIsFundingContract] = useState(false);
  const [isRetreivingFunds, setIsRetreivingFunds] = useState(false);
  const [isEnablingClaiming, setIsEnablingClaiming] = useState(false);

  const [itemsSold, setItemsSold] = useState<number>(0);
  const [liquidityFractionNumerator, setLiquidityFractionNumerator] =
    useState(1);
  const [liquidityFractionDenominator, setLiquidityFractionDenominator] =
    useState(2);
  const [liquidityProvided, setLiquidityProvided] = useState<boolean>(false);
  const [idoTokensAllocatedToLiquidity, setIdoTokensAllocatedToLiquidity] =
    useState<number>(0);
  const [payTokensAllocatedToLiquidity, setPayTokensAllocatedToLiquidity] =
    useState<number>(0);
  const [liquidityTokensReceived, setLiquidityTokensReceived] =
    useState<number>(0);

  const [isTemplateCreated, setIsTemplateCreated] = useState(
    template && template.templateId ? true : false
  );
  const [file, setFile] = useState<File | null>(null);
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const [templateId, setTemplateId] = useState<string>(
    template && template.templateId ? template.templateId : ''
  );

  const totalIdoTokensForSale = capTotal
    ? saleVersion === 8 || saleVersion === 10
      ? (capTotal *
          Number(project.ratio_idotoken_per_paytoken) *
          liquidityFractionDenominator) /
        liquidityFractionNumerator
      : capTotal * Number(project.ratio_idotoken_per_paytoken)
    : 0;
  const itemsSoldWithLiquidityTokens =
    (itemsSold * liquidityFractionDenominator) / liquidityFractionNumerator;

  const idoTokensToFundToTheContract =
    saleVersion && (saleVersion === 8 || saleVersion === 10)
      ? itemsSoldWithLiquidityTokens
      : totalIdoTokensForSale;
  const invested = itemsSold * Number(project.ratio_paytoken_per_idotoken);
  const percentage = ((invested / capTotal!) * 100).toFixed(1);

  const extractDateMonthAndTime = (timestamp: any) => {
    if (timestamp === undefined || timestamp === null) {
      return 'Not Available';
    }

    const dateObj = new Date(timestamp);

    const date = dateObj.getDate();
    const month = dateObj.toLocaleString('en-US', { month: 'short' });
    const time = dateObj.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${date} ${month}, ${time}`;
  };

  const handleSummary = () => {
    if (typeof selectedPrjectSummary === 'function') {
      selectedPrjectSummary(id);
    }
  };

  const handleProject = () => {
    if (typeof selectedProject === 'function') {
      selectedProject(id);
    }
  };

  const handleEditProjects = () => {
    if (typeof handleEdit === 'function') {
      handleEdit(id);
    }
  };

  const handleNetworkChange = async () => {
    if (!provider) return;
    try {
      await switchNetworkAsync?.(Number(chain));
      await new Promise((resolve) => setTimeout(resolve, 1000));
      window.location.reload();
    } catch (error) {
      console.warn('Something went wrong on switching network', error);
    }
  };

  const handleApprove = async () => {
    if (
      !idoTokenAddress ||
      !saleContractAddress ||
      !idoTokensToFundToTheContract ||
      !signer
    )
      return;
    setIsApproving(true);
    try {
      // @ts-ignore
      const { request } = await publicClient.simulateContract({
        account: userAddress as `0x${string}`,
        address: idoTokenAddress as `0x${string}`,
        abi: erc20ABI,
        functionName: 'approve',
        args: [
          saleContractAddress as `0x${string}`,
          parseUnits(idoTokensToFundToTheContract.toString(), idoTokenDecimals),
        ],
      });
      // @ts-ignore
      const txHash = await walletClient.writeContract(request);
      console.log('Token approval tx', txHash);
      // @ts-ignore
      const tx = await publicClient.waitForTransactionReceipt({
        hash: txHash,
      });
      console.log('Token approval mined', tx);
      setIsApproved(true);
    } catch (err) {
      console.log(err);
    }
    setIsApproving(false);
  };

  const handleInoApprove = async () => {
    if (!idoTokenAddress || !saleContractAddress || !signer) return;
    setIsApproving(true);
    try {
      // @ts-ignore
      const { request } = await publicClient.simulateContract({
        account: userAddress as `0x${string}`,
        address: idoTokenAddress as `0x${string}`,
        abi: erc1155Abi,
        functionName: 'setApprovalForAll',
        args: [saleContractAddress as `0x${string}`, true],
      });
      // @ts-ignore
      const txHash = await walletClient.writeContract(request);
      console.log('INO Token approval tx', txHash);
      // @ts-ignore
      const tx = await publicClient.waitForTransactionReceipt({
        hash: txHash,
      });
      console.log('INO Token approval mined', tx);
      setIsApproved(true);
    } catch (err) {
      console.log(err);
    }
    setIsApproving(false);
  };

  const handleFundContract = async () => {
    if (!saleContractAddress || !idoTokensToFundToTheContract) return;
    setIsFundingContract(true);
    try {
      // @ts-ignore
      const { request } = await publicClient.simulateContract({
        account: userAddress as `0x${string}`,
        address: saleContractAddress as `0x${string}`,
        abi: fsSaleV4,
        functionName: 'fundToContract',
        args: [
          parseUnits(idoTokensToFundToTheContract.toString(), idoTokenDecimals),
        ],
      });
      // @ts-ignore
      const txHash = await walletClient.writeContract(request);
      console.log('Token funding tx', txHash);
      // @ts-ignore
      const tx = await publicClient.waitForTransactionReceipt({
        hash: txHash,
      });
      console.log('Token funding mined', tx);
      setIsContractFunded(true);
    } catch (err) {
      console.log(err);
    }
    setIsFundingContract(false);
  };

  console.log(project);

  const handleInoFundContract = async () => {
    if (!saleContractAddress || !idoTokensToFundToTheContract) return;
    setIsFundingContract(true);
    try {
      // @ts-ignore
      const { request } = await publicClient.simulateContract({
        account: userAddress as `0x${string}`,
        address: saleContractAddress as `0x${string}`,
        abi: fsSaleV4Ino,
        functionName: 'fundInoToContract',
        args: [idoTokensToFundToTheContract.toString()],
      });
      // @ts-ignore
      const txHash = await walletClient.writeContract(request);
      console.log('INO Token funding tx', txHash);
      // @ts-ignore
      const tx = await publicClient.waitForTransactionReceipt({
        hash: txHash,
      });
      console.log('INO Token funding mined', tx);
      setIsContractFunded(true);
    } catch (err) {
      console.log(err);
    }
    setIsFundingContract(false);
  };

  const handleRetrieveFunds = async () => {
    if (!saleContractAddress || !contractBalance || !payTokenDecimals) return;
    setIsRetreivingFunds(true);
    try {
      const retrievableBalance =
        saleVersion === 8 || saleVersion === 10
          ? contractBalance -
            (contractBalance * liquidityFractionNumerator) /
              liquidityFractionDenominator
          : contractBalance;
      const parsedRetrievableBalance = parseUnits(
        retrievableBalance.toString(),
        payTokenDecimals
      );
      // @ts-ignore
      const { request } = await publicClient.simulateContract({
        account: userAddress as `0x${string}`,
        address: saleContractAddress as `0x${string}`,
        abi: fsSaleV4,
        functionName: 'withdrawPayTokens',
        args: [userAddress, parsedRetrievableBalance],
      });

      // @ts-ignore
      const txHash = await walletClient.writeContract(request);
      console.log('Retrieve fund tx', txHash);
      // @ts-ignore
      const tx = await publicClient.waitForTransactionReceipt({
        hash: txHash,
      });
      console.log('Retrieve fund mined', tx);
      setIsFundRetrieved(true);
      if (saleVersion === 8 || saleVersion === 10) setLiquidityProvided(true);
    } catch (err) {
      console.log(err);
    }
    setIsRetreivingFunds(false);
  };

  const handleClaimingStartDateChange = (dates: Date[]) => {
    if (dates.length > 0) {
      const value = dates[0].toISOString();
      setClaimingStartDate(value);
    }
  };

  const handleClaimingEndDateChange = (dates: Date[]) => {
    if (dates.length > 0) {
      const value = dates[0].toISOString();
      setClaimingEndDate(value);
    }
  };

  const handleEnableClaiming = async () => {
    if (
      !saleContract ||
      !claimingEndDate ||
      (!claimingStartDate && !cliffVesting)
    )
      return;
    setIsEnablingClaiming(true);
    try {
      if (cliffVesting && initialPercentageAllocation) {
        // @ts-ignore
        const { request } = await publicClient.simulateContract({
          account: userAddress as `0x${string}`,
          address: saleContractAddress as `0x${string}`,
          abi: fsSaleV4,
          functionName: 'setInitialPercentageAllocationIdoTokens',
          args: [initialPercentageAllocation],
        });
        // @ts-ignore
        const txHash = await walletClient.writeContract(request);
        console.log('Initial percentage allocation tx', txHash);
        // @ts-ignore
        const tx = await publicClient.waitForTransactionReceipt({
          hash: txHash,
        });
        console.log('Initial percentage allocation mined', tx);
      }
      const claimingStartDateTimestamp = cliffVesting
        ? new Date(claimingEndDate).getTime() / 1000 - 1
        : new Date(claimingStartDate).getTime() / 1000;
      // @ts-ignore
      const { request } = await publicClient.simulateContract({
        account: userAddress as `0x${string}`,
        address: saleContractAddress as `0x${string}`,
        abi: fsSaleV4,
        functionName: 'enableClaiming',
        args: [true, claimingStartDateTimestamp],
      });
      // @ts-ignore
      const txHash = await walletClient.writeContract(request);
      console.log('Enable claiming tx', txHash);
      // @ts-ignore
      const tx = await publicClient.waitForTransactionReceipt({
        hash: txHash,
      });
      console.log('Enable claiming mined', tx);

      // @ts-ignore
      const { request: request2 } = await publicClient.simulateContract({
        account: userAddress as `0x${string}`,
        address: saleContractAddress as `0x${string}`,
        abi: fsSaleV4,
        functionName: 'setEndDateOfClaimingTokens',
        args: [new Date(claimingEndDate).getTime() / 1000],
      });
      // @ts-ignore
      const txHash2 = await walletClient.writeContract(request2);
      console.log('Set end date tx', txHash2);
      // @ts-ignore
      const tx2 = await publicClient.waitForTransactionReceipt({
        hash: txHash2,
      });
      console.log('Set end date mined', tx2);
      setClaimPhaseEnabled(true);
    } catch (err) {
      console.log(err);
    }
    setIsEnablingClaiming(false);
  };

  const handleInoEnableClaiming = async () => {
    if (!saleContract || !claimingStartDate) return;
    setIsEnablingClaiming(true);
    try {
      const claimingStartDateTimestamp =
        new Date(claimingStartDate).getTime() / 1000;
      // @ts-ignore
      const { request } = await publicClient.simulateContract({
        account: userAddress as `0x${string}`,
        address: saleContractAddress as `0x${string}`,
        abi: fsSaleV4Ino,
        functionName: 'enableClaiming',
        args: [true, claimingStartDateTimestamp],
      });
      // @ts-ignore
      const txHash = await walletClient.writeContract(request);
      console.log('INO Enable claiming tx', txHash);
      // @ts-ignore
      const tx = await publicClient.waitForTransactionReceipt({
        hash: txHash,
      });
      console.log('INO Enable claiming mined', tx);

      const claimingEndDateTimestamp = claimingStartDateTimestamp + 1;
      // @ts-ignore
      const { request: request2 } = await publicClient.simulateContract({
        account: userAddress as `0x${string}`,
        address: saleContractAddress as `0x${string}`,
        abi: fsSaleV4Ino,
        functionName: 'setEndDateOfClaimingTokens',
        args: [claimingEndDateTimestamp],
      });
      // @ts-ignore
      const txHash2 = await walletClient.writeContract(request2);
      console.log('INO Set end date tx', txHash2);
      // @ts-ignore
      const tx2 = await publicClient.waitForTransactionReceipt({
        hash: txHash2,
      });
      console.log('INO Set end date mined', tx2);
      setClaimPhaseEnabled(true);
    } catch (err) {
      console.log(err);
    }
    setIsEnablingClaiming(false);
  };

  const handleToggleCliffVesting = (e: any) => {
    e.preventDefault();
    setCliffVesting(!cliffVesting);
  };

  const fp = useRef(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleTemplateEdit = async () => {
    const redirectUrl = window.location.href;
    const result = await getTemplateEditViewUrl(templateId, redirectUrl);
    window.open(result.url, '_blank');
  };

  const handleCreateTemplate = async () => {
    if (!file) {
      toast.error('Please select a file to upload', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    setIsCreatingTemplate(true);
    const result = await createTemplate(`${projectName} template`, file);
    if (result.templateId) {
      toast.success('Template created successfully', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      setIsTemplateCreated(true);
      setTemplateId(result.templateId);
    } else {
      toast.error('Failed to create template', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsCreatingTemplate(false);
  };

  useEffect(() => {
    if (template && template.templateId) {
      setTemplateId(template.templateId);
      setIsTemplateCreated(true);
    }
  }, [template]);

  useEffect(() => {
    if (!signer) return;
    signer
      .getAddress()
      .then((address) => {
        setUserAddress(address);
      })
      .catch((err) => {
        console.log('Something went wrong while retrieving address: ', err);
      });
  }, [signer]);

  useEffect(() => {
    if (
      !provider ||
      !idoTokenAddress ||
      !payTokenAddress ||
      !saleContractAddress ||
      isContractFunded ||
      !userAddress ||
      !idoTokensToFundToTheContract
    )
      return;
    (async () => {
      try {
        if (isINO) {
          const payDecimals = await getDecimals(payTokenAddress, provider);
          setPayTokenDecimals(payDecimals);

          const isApprovedForAllPromise = isApprovedForAll(
            idoTokenAddress,
            userAddress,
            saleContractAddress,
            provider
          );
          const balancePromise = getBalanceOf1155(
            idoTokenAddress,
            userAddress,
            0,
            provider
          );
          const values = await Promise.all([
            isApprovedForAllPromise,
            balancePromise,
          ]);
          const [isApproved, balance] = values;
          setIsApproved(isApproved);
          setHasEnoughBalance(Number(balance) >= idoTokensToFundToTheContract);
          return;
        }

        const idoDecimals = await getDecimals(idoTokenAddress, provider);
        const payDecimals = await getDecimals(payTokenAddress, provider);
        if (idoDecimals === 0) return;
        setIdoTokenDecimals(idoDecimals);
        setPayTokenDecimals(payDecimals);
        const allowancePromise = getAllowance(
          idoTokenAddress,
          userAddress,
          saleContractAddress,
          provider
        );
        const balancePromise = getBalanceOf(
          idoTokenAddress,
          userAddress,
          provider
        );
        const values = await Promise.all([allowancePromise, balancePromise]);
        const [allowance, balance] = values;
        setIsApproved(
          weiToUnit(allowance, idoDecimals)! >= idoTokensToFundToTheContract!
        );
        setHasEnoughBalance(
          weiToUnit(balance, idoDecimals)! >= idoTokensToFundToTheContract!
        );
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, isContractFunded, userAddress, idoTokensToFundToTheContract]);

  useEffect(() => {
    if (!saleContract) return;
    const contractFunded = saleContract.isIdoTokenFundedToContract();
    const claimingOpen = saleContract.isClaimingOpen();
    Promise.all([contractFunded, claimingOpen])
      .then((values) => {
        const [isFunded, isClaiming] = values;
        setIsContractFunded(isFunded);
        setClaimPhaseEnabled(isClaiming);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [saleContract]);

  useEffect(() => {
    if (!payTokenAddress || !saleContractAddress || !payTokenDecimals) return;
    (async () => {
      try {
        // @ts-ignore
        const balance = await publicClient.readContract({
          address: payTokenAddress as `0x${string}`,
          abi: erc20ABI,
          functionName: 'balanceOf',
          args: [saleContractAddress as `0x${string}`],
        });
        const formattedBalance = Number(formatUnits(balance, payTokenDecimals));
        setContractBalance(formattedBalance);
        if (formattedBalance === 0) {
          setIsFundRetrieved(true);
        }
      } catch (err) {
        console.log(
          'Something went wrong while retrieving contract balance: ',
          err
        );
      }
    })();
  }, [payTokenAddress, payTokenDecimals, publicClient, saleContractAddress]);

  useEffect(() => {
    if (!saleContractAddress || !idoTokenDecimals) return;
    (async () => {
      try {
        // @ts-ignore
        const itemsSold: bigint = await publicClient.readContract({
          address: saleContractAddress as `0x${string}`,
          abi: fsSaleWithLiquidityProviderABI,
          functionName: 'itemsSold',
        });
        setItemsSold(Number(formatUnits(itemsSold, idoTokenDecimals)));
        // @ts-ignore
        const liquidityFractionNumeratorPromise = publicClient.readContract({
          address: saleContractAddress as `0x${string}`,
          abi: fsSaleWithLiquidityProviderABI,
          functionName: 'liquidityFractionNumerator',
        });
        // @ts-ignore
        const liquidityFractionDenominatorPromise = publicClient.readContract({
          address: saleContractAddress as `0x${string}`,
          abi: fsSaleWithLiquidityProviderABI,
          functionName: 'liquidityFractionDenominator',
        });
        const values = await Promise.all([
          liquidityFractionNumeratorPromise,
          liquidityFractionDenominatorPromise,
        ]);
        const [numerator, denominator] = values;
        setLiquidityFractionNumerator(Number(numerator));
        setLiquidityFractionDenominator(Number(denominator));
      } catch (err) {
        console.log('Something went wrong while retrieving items sold:', err);
      }
    })();
  }, [publicClient, saleContractAddress, idoTokenDecimals]);

  useEffect(() => {
    async function getLiquidityInfo() {
      if (!saleContractAddress || !publicClient) return;
      try {
        // @ts-ignore
        const idoTokenAddedToLiquidityPromise = publicClient.readContract({
          address: saleContractAddress as `0x${string}`,
          abi: fsSaleWithLiquidityProviderABI,
          functionName: 'idoTokensAddedToLiquidity',
        });
        // @ts-ignore
        const payTokenAddedToLiquidityPromise = publicClient.readContract({
          address: saleContractAddress as `0x${string}`,
          abi: fsSaleWithLiquidityProviderABI,
          functionName: 'payTokensAddedToLiquidity',
        });
        // @ts-ignore
        const liquidityTokensRecievedPromise = publicClient.readContract({
          address: saleContractAddress as `0x${string}`,
          abi: fsSaleWithLiquidityProviderABI,
          functionName: 'liquidityTokensReceived',
        });
        const values = await Promise.all([
          idoTokenAddedToLiquidityPromise,
          payTokenAddedToLiquidityPromise,
          liquidityTokensRecievedPromise,
        ]);
        const [idoTokens, payTokens, liquidityTokens] = values;
        setIdoTokensAllocatedToLiquidity(
          Number(formatUnits(idoTokens as bigint, idoTokenDecimals))
        );
        setPayTokensAllocatedToLiquidity(
          Number(formatUnits(payTokens as bigint, payTokenDecimals))
        );
        setLiquidityTokensReceived(
          Number(formatUnits(liquidityTokens as bigint, 18))
        );
      } catch (error) {}
    }
    if ((saleVersion === 8 || saleVersion === 10) && liquidityProvided) {
      getLiquidityInfo();
    }
  }, [
    liquidityProvided,
    saleVersion,
    publicClient,
    saleContractAddress,
    idoTokenDecimals,
    payTokenDecimals,
  ]);

  useEffect(() => {
    if (
      (saleVersion === 8 || saleVersion === 10) &&
      claimPhaseEnabled &&
      saleContractAddress
    ) {
      publicClient
        // @ts-ignore
        .readContract({
          address: saleContractAddress as `0x${string}`,
          abi: fsSaleWithLiquidityProviderABI,
          functionName: 'liquityProvided',
        })
        .then((data: any) => {
          setLiquidityProvided(data);
        });
    }
  }, [saleVersion, publicClient, claimPhaseEnabled, saleContractAddress]);

  const handleEmbed = () => {
    const chainId = Number(chain);
    const isTestnet =
      chainId === 4002 ||
      chainId === 80002 ||
      chainId === 97 ||
      chainId === 5 ||
      chainId === 421611 ||
      chainId === 420 ||
      chainId === 43113;
    const isINO = saleVersion === 3;
    window.open(
      `https://embed.futurestarter.xyz/?path=/story/pages-projectpage--default&args=id:${id};isTestnet:${isTestnet};isINO:${isINO}`,
      '_blank'
    );
  };

  return (
    <div className="MainContent bg-zinc-900 sm:pt-10 p-10 rounded-[30px] rounded-bl-[20px] rounded-br-[20px] backdrop-blur-[100px] flex-col justify-start items-start gap-[30px] inline-flex">
      <div className="HeadingWithLogoAndLinks justify-start items-center gap-5 inline-flex">
        <img
          className="Rectangle6 w-[66px] h-[66px] rounded-[10px]"
          src={image}
          alt="new_img"
        />
        <div className="Frame38 flex-col justify-start items-start inline-flex">
          <div className="StumbleUponRumble text-white text-xl font-semibold">
            {projectName}
          </div>
          <div className="Links self-stretch justify-start items-center gap-2.5 inline-flex">
            <div className="Card pt-2 pb-[9px]   justify-center items-center gap-2 flex cursor-pointer">
              <button
                className="SeeSummary text-purple-500 text-sm font-normal"
                onClick={handleSummary}
              >
                See summary
              </button>
            </div>
            <div className="Frame5671 pt-px justify-start items-center gap-2.5 flex">
              <div className="Ellipse1 w-[3px] h-[3px] bg-neutral-700 rounded-full" />
            </div>
            <div className="Card pt-2 pb-[9px] -[60px]  justify-center items-center gap-2 flex cursor-pointer">
              <button
                className="Delete text-red-400 text-sm font-normal"
                onClick={async () => {
                  const result = await deleteProject(id);
                  if (result.status === 200) {
                    toast.success('Project Successfully Deleted!', {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 3000,
                    });
                  } else {
                    toast.error('Failed to delete Project', {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 3000,
                    });
                  }
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="Frame5701 justify-start items-center gap-[15px] inline-flex">
        <div className="Frame5699 justify-start items-center gap-1.5 flex">
          <img
            src={Chain}
            className="w-[11px] h-[12px] relative top-[2px] inline-block"
            alt={Chain}
          />
          <div className="Info flex-col justify-start items-start gap-0.5 inline-flex">
            <div className="ChainId2096 text-center">
              <span className="text-neutral-400 text-sm font-normal">
                Chain ID:{' '}
              </span>
              <span className="text-white text-sm font-normal">{chain}</span>
            </div>
          </div>
        </div>
        <div className="Frame5670 pt-px justify-start items-center gap-2.5 flex">
          <div className="Ellipse1 w-[3px] h-[3px] bg-neutral-700 rounded-full" />
        </div>
        <div className="Frame5700 justify-start items-start gap-1.5 flex">
          <div className="VuesaxBoldFlag w-3 h-3 justify-center items-center inline-flex mt-1.5">
            <img
              src={Flag}
              className="w-3 h-3  relative top-[2px] inline-block "
              alt={Flag}
            />
          </div>
          <div className="KycStatusFalse text-center">
            <span className="text-neutral-400 text-sm font-normal">
              KYC Status:{' '}
            </span>
            <span className="text-white text-sm font-normal">{`${kyc}`}</span>
          </div>
        </div>
      </div>
      <div className="Frame5682 justify-start items-start gap-[50px] inline-flex">
        <div className="Frame5669 flex-col justify-start items-start gap-[15px] inline-flex">
          <div className="Frame5703 justify-start items-center gap-2.5 inline-flex">
            <div className="VuesaxBoldShopRemove w-10 h-10 justify-center items-center flex">
              <img src={Sale} className="w-10 h-10  inline-block" alt={Sale} />
            </div>
            <div className="Info flex-col justify-start items-start inline-flex">
              <div className="SaleType text-center text-neutral-400 text-sm font-normal">
                Sale Type
              </div>
              <div className="FirstComeFirstServeFcfs text-white text-sm font-normal">
                {projectType === 'fcfs'
                  ? 'First Come First Serve'
                  : projectType === 'lottery'
                    ? 'Lottery'
                    : 'WhiteList'}
              </div>
            </div>
          </div>
          <div className="Frame5704 justify-start items-center gap-2.5 inline-flex">
            <div className="VuesaxBoldBarcode w-[45px] h-[45px] justify-center items-center flex">
              <img
                src={Barcode}
                className="w-[45px] h-[45px]  inline-block"
                alt={Barcode}
              />
            </div>
            <div className="Info flex-col justify-start items-start inline-flex">
              <div className="UniqueId text-center text-neutral-400 text-sm font-normal">
                Unique ID
              </div>
              <div className="A0831d223b1C4adF82ad7938483 text-white text-sm font-normal">
                {id}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Frame5698 flex-col justify-start items-start gap-5 flex">
        <div className="Frame5696 flex-col justify-start items-start gap-1 flex">
          <div className="WishlistDate text-center text-neutral-400 text-sm font-normal">
            Wishlist Date
          </div>
          <div
            className={`Frame5695 justify-start items-center ${wishlistStartDate === null || wishlistStartDate === undefined ? 'gap-[37px]' : 'gap-[9px]'}  inline-flex`}
          >
            <div className="Frame5670 justify-start items-center gap-1.5 flex">
              <div className="VuesaxBoldCalendar w-[17px] h-[17px] justify-center items-center flex">
                <img
                  src={Calender}
                  className="w-[17px] h-[17px]  inline-block"
                  alt={Calender}
                />
              </div>
              <div className="Start24Jun0232Pm">
                <span className="text-neutral-400 text-sm font-normal">
                  Start:
                </span>
                <span className="text-white text-sm font-normal">
                  {' '}
                  {extractDateMonthAndTime(wishlistStartDate)}{' '}
                </span>
              </div>
            </div>
            <div className="Frame5670 justify-start items-center gap-1.5 flex">
              <img
                src={Target}
                className="w-[17px] h-[17px]  inline-block"
                alt={Target}
              />
              <div className="End01Jul0232Pm">
                <span className="text-neutral-400 text-sm font-normal">
                  End:
                </span>
                <span className="text-white text-sm font-normal">
                  {' '}
                  {extractDateMonthAndTime(wishlistEndDate)}{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="Frame5697 flex-col justify-start items-start gap-1 flex">
          <div className="FundingDate text-center text-neutral-400 text-sm font-normal">
            Funding Date
          </div>
          <div className="Frame5695 justify-start items-center gap-[9px] inline-flex">
            <div className="Frame5670 justify-start items-center gap-1.5 flex">
              <div className="VuesaxBoldCalendar w-[18px] h-[18px] justify-center items-center flex">
                <img
                  src={Calender}
                  className="w-[17px] h-[17px]  inline-block"
                  alt={Calender}
                />
              </div>
              <div className="Start01Jul0615Pm">
                <span className="text-neutral-400 text-sm font-normal">
                  Start:
                </span>
                <span className="text-white text-sm font-normal">
                  {' '}
                  {extractDateMonthAndTime(start_date_funding)}{' '}
                </span>
              </div>
            </div>
            <div className="Frame5670 justify-start items-center gap-1.5 flex">
              <img
                src={Target}
                className="w-[17px] h-[17px]  inline-block"
                alt={Target}
              />
              <div className="End15Jul0000Am">
                <span className="text-neutral-400 text-sm font-normal">
                  End:
                </span>
                <span className="text-white text-sm font-normal">
                  {' '}
                  {extractDateMonthAndTime(end_date_funding)}{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(saleVersion === 8 || saleVersion === 10) && (
        <section className="flex flex-col">
          <div className="flex items-center gap-1">
            <h5 className="text-neutral-400 text-[14px] font-normal">{`Percentage of ${project.ido_token_symbol}-${project.pay_token_symbol} allocated to liquidity:`}</h5>
            <p className="text-white text-sm font-normal">
              {`${((liquidityFractionNumerator / liquidityFractionDenominator) * 100).toFixed(1)}%`}
            </p>
          </div>

          {liquidityProvided && (
            <section className="flex flex-col">
              <div className="flex items-center gap-1">
                <h5 className="text-neutral-400 text-[14px] font-normal">{`Amount of ${project.ido_token_symbol} added to the pool:`}</h5>
                <p className="text-white text-sm font-normal">
                  {idoTokensAllocatedToLiquidity.toFixed(2)}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <h5 className="text-neutral-400 text-[14px] font-normal">{`Amount of ${project.pay_token_symbol} added to the pool:`}</h5>
                <p className="text-white text-sm font-normal">
                  {payTokensAllocatedToLiquidity.toFixed(2)}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <h5 className="text-neutral-400 text-[14px] font-normal">{`Amount of liquidity tokens recieved:`}</h5>
                <p className="text-white text-sm font-normal">
                  {liquidityTokensReceived.toFixed(2)}
                </p>
              </div>
            </section>
          )}
        </section>
      )}

      <div className="Frame5664 self-stretch h-[48.40px] flex-col justify-start items-start gap-[5px] flex">
        <div className="Frame22 self-stretch justify-between items-end gap-[5px] inline-flex">
          <div className="Frame22 justify-start items-center gap-[5px] flex">
            <div className="32519 text-white text-[16px] font-semibold">
              {`${invested} ${project.pay_token_symbol}`}
            </div>
            <div className="Invested text-neutral-400 text-[13px] font-normal">
              invested
            </div>
          </div>
          <div className="5 text-white text-[13px] font-semibold">{`${percentage}%`}</div>
        </div>
        <div className="Group21 self-stretch h-[10.40px] relative">
          <div className="Rectangle9 w-full h-2.5 left-0 right-0 top-[0.40px] absolute bg-black rounded-[10px]" />
          <div
            className={`Rectangle10 h-2.5 left-0 top-0 absolute bg-purple-500 rounded-[10px]`}
            style={{
              width: `${percentage}%`,
            }}
          />
        </div>
        {capTotal ? (
          <div className="67481Left relative top-[8px] text-neutral-400 text-[13px] font-normal">
            {`${capTotal - invested} ${project.pay_token_symbol} left`}
          </div>
        ) : null}
      </div>

      <div className="Frame5702 flex  gap-3.5 w-full flex-wrap justify-center items-center">
        <button
          onClick={handleProject}
          className="Button2 pl-[18px] pr-[26px] py-2.5 rounded-[60px] border border-purple-500 justify-center items-center gap-1.5 flex cursor-pointer"
        >
          <div className="VuesaxBoldTruckTick w-4 h-4 justify-center items-center flex">
            <img src={Truck} className="w-4 h-4  inline-block" alt={Truck} />
          </div>
          <div className="Label text-center text-purple-500 text-sm font-semibold leading-[18px]">
            Deploy{' '}
          </div>
        </button>
        <button
          onClick={handleEmbed}
          className="Button3 pl-[18px] pr-[26px] py-2.5 rounded-[60px] border border-purple-500 justify-center items-center gap-1.5 flex cursor-pointer"
        >
          <div className="VuesaxBoldCodeCircle w-4 h-4 justify-center items-center flex">
            <img src={Code} className="w-4 h-4  inline-block" alt={Code} />
          </div>
          <div className="Label text-center text-purple-500 text-sm font-semibold leading-[18px]">
            Embed
          </div>
        </button>
        <button
          onClick={handleEditProjects}
          className="Button1 pl-[18px] pr-[26px] py-2.5 bg-purple-500 rounded-[20px] justify-center items-center gap-1.5 flex cursor-pointer"
        >
          <div className="VuesaxBoldEdit2 w-3.5 h-3.5 justify-center items-center flex">
            <img src={Edit} className="w-3.5 h-3.5  inline-block" alt={Edit} />
          </div>
          <div className="Label text-center text-black text-sm font-semibold leading-[18px]">
            Edit
          </div>
        </button>
      </div>
      {!claimPhaseEnabled &&
        isFundingCompleted &&
        isContractFunded &&
        saleContract &&
        !isINO && (
          <div className="w-full">
            <div className="Card w-full p-3.5 rounded-[10px] border border-neutral-700 flex-row justify-between items-start inline-flex">
              <div className="Frame38 justify-start items-center gap-[5px] inline-flex">
                <div className="Label text-neutral-400 text-sm font-normal">
                  Cliff Vesting
                </div>
              </div>
              <div className="relative Frame5724 self-stretch justify-between items-start gap-2.5 inline-flex">
                <div className="Label text-white text-sm font-normal">
                  {cliffVesting ? 'Yes' : 'No'}
                </div>
                <div
                  className="relative inline-block"
                  onClick={(e) => handleToggleCliffVesting(e)}
                >
                  <div
                    className={`peer pl-[22.35px] pr-[1.35px] py-[1.35px] w-[42px] h-[21px] cursor-pointer appearance-none rounded-full  ${
                      cliffVesting ? 'bg-purple-500' : 'bg-neutral-700'
                    }  `}
                  ></div>
                  <span
                    className={`pointer-events-none absolute  top-[1.5px] block w-[18.29px] h-[18.29px] rounded-full bg-white transition-all duration-200 ${
                      cliffVesting
                        ? 'transform translate-x-[22px]'
                        : 'transform translate-x-[0px]'
                    }`}
                  />
                </div>
              </div>
            </div>
            {cliffVesting && (
              <div className=" mt-1 text-sm">
                * Claiming starts immediately when it's enabled
              </div>
            )}
          </div>
        )}
      {!claimPhaseEnabled &&
        isFundingCompleted &&
        isContractFunded &&
        saleContract && (
          <div className="flex flex-row gap-5 self-center w-full justify-center">
            {!cliffVesting && (
              <label
                htmlFor="claiming-start-date"
                className="Frame5664 w-[160px] p-[8px] sm:p-[10px] bg-black bg-opacity-30 rounded-[15px] border border-white border-opacity-20 flex-col justify-between items-start gap-2.5 inline-flex"
              >
                <div className="VuesaxLinearCalendar justify-center items-center inline-flex">
                  <div className="Calendar  relative ">
                    <Flatpickr
                      className="w-full h-full absolute top-0 left-0 opacity-0 "
                      placeholder="Select Claiming Start Date"
                      data-enable-time
                      onChange={handleClaimingStartDateChange}
                      options={{ enableTime: true }}
                      ref={fp}
                    />
                    <img src={calendar} className="w-6 h-6" alt="Calendar" />
                  </div>
                </div>
                <div className="SelectDateAndTime self-stretch text-white text-opacity-20 text-base font-normal">
                  {claimingStartDate
                    ? extractDateMonthAndTime(claimingStartDate)
                    : 'Select claiming start date'}
                </div>
              </label>
            )}
            {!isINO && (
              <div className={`${cliffVesting ? 'w-full' : 'w-[160px]'}`}>
                <label
                  htmlFor="claiming-end-date"
                  className={`Frame5664 ${cliffVesting ? 'w-full flex-row' : 'w-[160px] flex-col'} p-[8px] sm:p-[10px] bg-black bg-opacity-30 rounded-[15px] border border-white border-opacity-20 justify-between items-start gap-2.5 inline-flex`}
                >
                  <div className="VuesaxLinearCalendar justify-center items-center inline-flex">
                    <div className="Calendar  relative ">
                      <Flatpickr
                        className="w-full h-full absolute top-0 left-0 opacity-0 "
                        placeholder="Select Claiming End Date"
                        data-enable-time
                        onChange={handleClaimingEndDateChange}
                        options={{ enableTime: true }}
                        ref={fp}
                      />
                      <img src={calendar} className="w-6 h-6" alt="Calendar" />
                    </div>
                  </div>
                  <div className="SelectDateAndTime self-stretch text-white text-opacity-20 text-base font-normal">
                    {claimingEndDate
                      ? extractDateMonthAndTime(claimingEndDate)
                      : 'Select claiming end date'}
                  </div>
                </label>
                {cliffVesting && (
                  <div className="Frame15 mt-3 flex-row justify-start items-center gap-2.5 inline-flex">
                    <div className="Label text-neutral-400 text-sm font-normal">
                      Initial Percentage Allocation
                    </div>
                    <div className="Card w-[145px] sm:w-[170px]  bg-neutral-900 rounded-[10px] border border-neutral-700 justify-start items-center inline-flex">
                      <input
                        type="number"
                        inputMode="decimal"
                        placeholder="0"
                        value={
                          initialPercentageAllocation
                            ? initialPercentageAllocation
                            : ''
                        }
                        onChange={(e) => {
                          const percentage = Number(
                            Number(e.target.value).toFixed(2)
                          );
                          setInitialPercentageAllocation(
                            percentage > 100 ? 100 : percentage
                          );
                        }}
                        className="w-full h-full px-3.5 py-2 bg-neutral-900 border-0 rounded-[10px] text-neutral-300 text-sm font-normal focus:border-0 focus:ring-0 p-0 m-0"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

      {projectName && projectName.includes('--rwa') && (
        <div className="flex flex-col w-full gap-2">
          {!isTemplateCreated && (
            <input
              type="file"
              onChange={handleFileChange}
              className="cursor-pointer"
            />
          )}
          {!isTemplateCreated ? (
            <button
              className={`${isCreatingTemplate ? 'cursor-wait' : 'cursor-pointer'} Button1 px-[18px] py-2.5 bg-purple-500 rounded-[20px] justify-center items-center gap-1.5 flex cursor-pointer Label text-center text-black text-sm font-semibold leading-[18px] w-full`}
              onClick={handleCreateTemplate}
              disabled={isCreatingTemplate}
            >
              {isCreatingTemplate ? 'Creating template...' : 'Create Template'}
            </button>
          ) : (
            <button
              className="Button1 px-[18px] py-2.5 bg-purple-500 rounded-[20px] justify-center items-center gap-1.5 flex cursor-pointer Label text-center text-black text-sm font-semibold leading-[18px] w-full"
              onClick={handleTemplateEdit}
            >
              Edit Docusign Template
            </button>
          )}
        </div>
      )}
      {saleContract && (
        <button
          className="Button1 px-[18px] py-2.5 bg-purple-500 rounded-[20px] justify-center items-center gap-1.5 flex cursor-pointer Label text-center text-black text-sm font-semibold leading-[18px] w-full"
          onClick={() => {
            if (networkChangeRequired) {
              handleNetworkChange();
              return;
            }
            if (!isApproved && !isContractFunded) {
              isINO ? handleInoApprove() : handleApprove();
              return;
            }
            if (!isContractFunded) {
              isINO ? handleInoFundContract() : handleFundContract();
              return;
            }
            if (!claimPhaseEnabled && isFundingCompleted) {
              isINO ? handleInoEnableClaiming() : handleEnableClaiming();
              return;
            }
            handleRetrieveFunds();
            return;
          }}
        >
          {networkChangeRequired
            ? `Switch Network`
            : !hasEnoughBalance
              ? `Insufficient Balance`
              : !isApproved && !isContractFunded
                ? isApproving
                  ? 'Approving...'
                  : `Approve`
                : !isContractFunded
                  ? isFundingContract
                    ? 'Funding...'
                    : `Fund Contract`
                  : !claimPhaseEnabled && isFundingCompleted
                    ? isEnablingClaiming
                      ? 'Enabling...'
                      : `Enable Claiming`
                    : // : weiToUnit(contractBalance!)! > 0
                      !isFundRetrieved
                      ? isRetreivingFunds
                        ? 'Retrieving...'
                        : saleVersion === 8 || saleVersion === 10
                          ? `Add liquidity & retrieve funds!`
                          : `Retrieve Funds`
                      : `No Funds`}
        </button>
      )}
    </div>
  );
};

export default SalesCard;
