import React, { useCallback, useEffect, useState } from 'react';
import { erc20ABI, usePublicClient } from 'wagmi';
import { formatUnits, parseUnits } from 'viem';
import { prepareContractCall, getContract, waitForReceipt } from 'thirdweb';
import { polygonAmoy } from 'thirdweb/chains';
import { useSendTransaction, useActiveAccount } from 'thirdweb/react';
import { toast } from 'react-toastify';

import { client } from '../../config/thirdwebConfig';
import useModal from '../../hooks/useModal';
import SaleCardMenu from './SaleCardMenu';
import TooltipMessage from './Tooltip';
import EditModal from '../EditModal/Edit';
import DeployModal from '../DeployContracts/Deploy';
import { DocumentActions } from '../DocumentActions';
import {
  more,
  link,
  flag,
  shopingBag,
  layoutList,
  calendar,
  target,
  edit,
  editWhite,
  editBlack,
  code,
  codeWhite,
  codeBlack,
  truck,
  truckWhite,
  truckBlack,
  arrowDown,
} from '../../assets';
import {
  extractNameAndTag,
  extractDateMonthAndTime,
} from '../../utils/conversion';
import fsSaleWithLiquidityProviderABI from '../../abis/fsSaleWithLiquidityProviderABI.json';
import useDocusignApi from '../../hooks/useDocusign';

interface ISaleCardProps {
  project: any;
  templateHash: { [templateName: string]: any };
  refetchProjects: () => void;
}

const SaleCard: React.FC<ISaleCardProps> = ({
  project,
  templateHash,
  refetchProjects,
}) => {
  const publicClient = usePublicClient();
  const { openModal, closeModal } = useModal();
  const { mutateAsync: sendTx } = useSendTransaction();
  const { mutateAsync: sendFundToContractTx } = useSendTransaction();
  const activeAccount = useActiveAccount();
  const { getTemplateEditViewUrl, createTemplate, getTemplateName } =
    useDocusignApi();

  const projectLogo = project.logo_image_url;
  const name = extractNameAndTag(project.name).name;
  const tag = extractNameAndTag(project.name).tag;
  const chainId = project.chain;
  const kycStatus = project.with_kyc;
  const saleType = project.with_lottery
    ? 'Auction sale'
    : project.with_whitelist
      ? 'Registered sale'
      : 'Open sale';
  const uniqueId = project.id;
  const wishlistStart = extractDateMonthAndTime(
    project.start_date_whitelist
      ? project.start_date_whitelist
      : project.start_date_funding
  );
  const wishlistEnd = extractDateMonthAndTime(
    project.end_date_whitelist
      ? project.end_date_whitelist
      : project.start_date_funding
  );
  const fundingStart = extractDateMonthAndTime(project.start_date_funding);
  const fundingEnd = extractDateMonthAndTime(project.end_date_funding);
  const capTotal = project.cap_total_dollars;

  const [address, setAddress] = useState<string | null>(null);
  const [itemsSold, setItemsSold] = useState<number>(0);
  const [toggleCardMenu, setToggleCardMenu] = useState<boolean>(false);
  const [toggleTooltip, setToggleTooltip] = useState<boolean>(false);
  const [refetchLocalStorageData, setRefetchLocalStorageData] =
    useState<string>('');
  const [editStepStatus, setEditStepStatus] = useState<
    'prevComplete' | 'actionReq' | 'done'
  >('actionReq');
  const [embedStepStatus, setEmbedStepStatus] = useState<
    'prevComplete' | 'actionReq' | 'done'
  >('prevComplete');
  const [deployStepStatus, setDeployStepStatus] = useState<
    'prevComplete' | 'actionReq' | 'done'
  >('prevComplete');
  const [allStepsDone, setAllStepsDone] = useState(false);
  const [toggleActionDropdown, setToggleActionDropdown] = useState(false);

  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [isExecutingAction, setIsExecutingAction] = useState(false);
  const [isFundToContractStepDone, setIsFundToContractStepDone] =
    useState(false);
  const [isEnableClaimingStepDone, setIsClaimingStepDone] = useState(false);
  const [contractBalance, setContractBalance] = useState<number>(0);
  const [isRetrieveFundStepDone, setIsRetrieveFundStepDone] = useState(false);
  const [refecthActionsLocalStorageData, setRefetchActionsLocalStorageData] =
    useState<string>('');
  const [toggleDocumentActions, setToggleDocumentActions] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isTemplateCreated, setIsTemplateCreated] = useState(false);
  const [templateId, setTemplateId] = useState<string>('');
  const [templateName, setTemplateName] = useState<string | null>(null);

  const investedAmount =
    itemsSold * Number(project.ratio_paytoken_per_idotoken);
  const investedPercentage = `${((investedAmount / capTotal) * 100).toFixed(1)}%`;
  const remainingAmount = capTotal - investedAmount;
  const totalTokensToFund: bigint = BigInt(
    capTotal / Number(project.ratio_paytoken_per_idotoken)
  );

  const openEditModal = () => {
    openModal(
      <EditModal
        onClose={() => {
          closeModal();
          setRefetchLocalStorageData(
            `refetchLocalStorageData - ${Date.now().toString()}`
          );
          refetchProjects();
        }}
        project={project}
      />
    );
  };

  const openDeployModal = () => {
    openModal(
      <DeployModal
        onClose={() => {
          closeModal();
          setRefetchLocalStorageData(
            `refetchLocalStorageData - ${Date.now().toString()}`
          );
          refetchProjects();
        }}
        project={project}
      />
    );
  };

  useEffect(() => {
    const fundToContractStepDone = localStorage.getItem(
      `fundToContractStepDone-${project.id}`
    );
    if (fundToContractStepDone) {
      setIsFundToContractStepDone(true);
    }

    const enableClaimingStepDone = localStorage.getItem(
      `enableClaimingStepDone-${project.id}`
    );
    if (enableClaimingStepDone) {
      setIsClaimingStepDone(true);
    }

    const withdrawPayTokensStepDone = localStorage.getItem(
      `withdrawPayTokensStepDone-${project.id}`
    );
    if (withdrawPayTokensStepDone) {
      setIsRetrieveFundStepDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refecthActionsLocalStorageData]);

  useEffect(() => {
    const editStepDone = localStorage.getItem(`editStepDone-${project.id}`);
    const embedStepDone = localStorage.getItem(`embedStepDone-${project.id}`);
    const deployStepDone = localStorage.getItem(`deployStepDone-${project.id}`);
    if (deployStepDone) {
      setEditStepStatus('done');
      setEmbedStepStatus('done');
      setDeployStepStatus('done');
      setAllStepsDone(true);
    } else if (embedStepDone) {
      setEditStepStatus('done');
      setEmbedStepStatus('done');
      setDeployStepStatus('actionReq');
    } else if (editStepDone) {
      setEditStepStatus('done');
      setEmbedStepStatus('actionReq');
      setDeployStepStatus('prevComplete');
    } else {
      setEditStepStatus('actionReq');
      setEmbedStepStatus('prevComplete');
      setDeployStepStatus('prevComplete');
    }
  }, [refetchLocalStorageData, project.id]);

  useEffect(() => {
    (async () => {
      if (
        project.sale_contract_address !== null &&
        project.sale_contract_address.includes('0x')
      ) {
        try {
          // @ts-ignore
          const soldAmount = (await publicClient.readContract({
            address: project.sale_contract_address as `0x${string}`,
            abi: fsSaleWithLiquidityProviderABI,
            functionName: 'itemsSold',
          })) as bigint;
          setItemsSold(Number(formatUnits(soldAmount, 0)));
        } catch (err) {
          console.warn('Something went wrong while fetching items sold', err);
        }
      }
    })();
  }, [project.sale_contract_address, publicClient]);

  useEffect(() => {
    (async () => {
      try {
        // @ts-ignore
        const balance = await publicClient.readContract({
          address: project.paytoken_contract_address as `0x${string}`,
          abi: erc20ABI,
          functionName: 'balanceOf',
          args: [project.sale_contract_address as `0x${string}`],
        });
        const formattedBalance = Number(formatUnits(balance, 6));
        setContractBalance(formattedBalance);
        // if (formattedBalance === 0) {
        //   setIsRetrieveFundStepDone(true);
        // }
      } catch (err) {
        console.log(
          'Something went wrong while retrieving contract balance: ',
          err
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicClient]);

  useEffect(() => {
    if (activeAccount && activeAccount.address) {
      setAddress(activeAccount.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount]);

  useEffect(() => {
    const templateName = getTemplateName(`${project.name} template`);
    const template = templateHash[templateName];
    if (template) {
      setTemplateName(templateName);
      setTemplateId(template.templateId);
      setIsTemplateCreated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerFileInput = () => {
    const fileInput = document.getElementById('file-upload');
    fileInput?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleFundContract = useCallback(async () => {
    setIsExecutingAction(true);
    try {
      toast.info('Approving tokens...', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      const tokenContract = getContract({
        address: project.token_contract_address,
        chain: polygonAmoy,
        client,
      });

      const approveTransaction = prepareContractCall({
        contract: tokenContract,
        method: 'function setApprovalForAll(address operator, bool approved)',
        params: [project.sale_contract_address, true],
      });
      const approveTx = await sendTx(approveTransaction);
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: approveTx.transactionHash,
      });

      toast.info('Funding contract...', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      const saleContract = getContract({
        address: project.sale_contract_address,
        chain: polygonAmoy,
        client,
      });
      const fundToContractTransaction = prepareContractCall({
        contract: saleContract,
        method: 'function fundInoToContract(uint256 _amountInInoTokens)',
        params: [totalTokensToFund],
      });
      const fundToContractTx = await sendFundToContractTx(
        fundToContractTransaction
      );
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: fundToContractTx.transactionHash,
      });

      toast.success('Contract funded successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      localStorage.setItem(`fundToContractStepDone-${project.id}`, 'true');
      setRefetchActionsLocalStorageData(
        `refetchActionsLocalStorageData - ${Date.now().toString()}`
      );
      setSelectedAction(null);
    } catch (error) {
      console.error('Error during contract funding:', error);
      toast.error('Error funding the contract. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecutingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, totalTokensToFund, sendTx, sendFundToContractTx]);

  const handleEnableClaiming = useCallback(async () => {
    setIsExecutingAction(true);
    try {
      const claimingStartTime: bigint = BigInt(Math.floor(Date.now() / 1000));
      const saleContract = getContract({
        address: project.sale_contract_address,
        chain: polygonAmoy,
        client,
      });
      const enableClaimingTransaction = prepareContractCall({
        contract: saleContract,
        method:
          'function enableClaiming(bool _isClaimingOpen, uint256 _startDateOfClaimingTokens)',
        params: [true, claimingStartTime],
      });
      const enableClaimingTx = await sendTx(enableClaimingTransaction);
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: enableClaimingTx.transactionHash,
      });

      const setEndDateOfClaimingTokensTransaction = prepareContractCall({
        contract: saleContract,
        method:
          'function setEndDateOfClaimingTokens(uint256 _endDateOfClaimingTokens)',
        params: [claimingStartTime + BigInt(1)],
      });
      const setEndDateOfClaimingTokensTx = await sendTx(
        setEndDateOfClaimingTokensTransaction
      );
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: setEndDateOfClaimingTokensTx.transactionHash,
      });

      toast.success('Claiming enabled successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      localStorage.setItem(`enableClaimingStepDone-${project.id}`, 'true');
      setRefetchActionsLocalStorageData(
        `refetchActionsLocalStorageData - ${Date.now().toString()}`
      );
      setSelectedAction(null);
    } catch (error) {
      console.error('Error enabling claiming:', error);
      toast.error('Error enabling claiming. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecutingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, sendTx]);

  const handleRetrieveContractBalance = useCallback(async () => {
    if (!address) {
      toast.error('Address not found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    setIsExecutingAction(true);
    try {
      const parsedRetrievableBalance = parseUnits(
        contractBalance.toString(),
        6
      );
      const saleContract = getContract({
        address: project.sale_contract_address,
        chain: polygonAmoy,
        client,
      });
      const withdrawPayTokensTransaction = prepareContractCall({
        contract: saleContract,
        method:
          'function withdrawPayTokens(address _owner, uint256 _payTokensToWithdraw)',
        params: [address, parsedRetrievableBalance],
      });
      const withdrawPayTokensTx = await sendTx(withdrawPayTokensTransaction);
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: withdrawPayTokensTx.transactionHash,
      });

      toast.success('Tokens retrieved successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      localStorage.setItem(`withdrawPayTokensStepDone-${project.id}`, 'true');
      setRefetchActionsLocalStorageData(
        `refetchActionsLocalStorageData - ${Date.now().toString()}`
      );
      setSelectedAction(null);
    } catch (error) {
      console.error('Error retrieving contract balance:', error);
      toast.error('Error retrieving contract balance. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecutingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, contractBalance, client, sendTx]);

  const handleActionSelect = (action: string) => {
    setSelectedAction(action);
  };

  const handleGoClick = () => {
    if (!selectedAction) return;
    switch (selectedAction) {
      case 'Edit':
        openEditModal();
        break;
      case 'Embed':
        window.open(
          `https://embed.futurestarter.xyz/?path=/story/pages-rwaprojectpage--default&args=id:${project.id};isTestnet:${true};isINO:${project.sale_version === 3}`,
          '_blank'
        );
        localStorage.setItem(`embedStepDone-${project.id}`, 'true');
        setRefetchLocalStorageData(
          `refetchLocalStorageData - ${Date.now().toString()}`
        );
        break;
      case 'Deploy':
        openDeployModal();
        break;
      case 'Fund Contract':
        handleFundContract();
        break;
      case 'Enable Claiming':
        handleEnableClaiming();
        break;
      case 'Retrieve Balance':
        handleRetrieveContractBalance();
        break;
      default:
        break;
    }
  };

  const handleCreateTemplate = async () => {
    if (!selectedFile) return;
    try {
      toast.info('Creating template...', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      const result = await createTemplate(
        `${project.name} template`,
        selectedFile
      );
      if (result.templateId) {
        toast.success('Template created successfully', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setTemplateId(result.templateId);
        setIsTemplateCreated(true);
      } else {
        toast.error('Failed to create template', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error creating template:', error);
      toast.error('Error creating template. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleEditTemplate = async () => {
    try {
      toast.info('Opening template editor...', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      const redirectUrl = window.location.href;
      const result = await getTemplateEditViewUrl(templateId, redirectUrl);
      window.open(result.url, '_blank');
    } catch (error) {
      console.error('Error editing template:', error);
      toast.error('Error editing template. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const documentActions = [
    !isTemplateCreated
      ? {
          label: 'Create Template',
          onClick: () => {
            if (!selectedFile) {
              toast.error('Please select a file to upload', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              return;
            }
            handleCreateTemplate();
          },
        }
      : {
          label: 'Edit Document',
          onClick: () => {
            if (!templateId) {
              toast.error('No template found to edit', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              return;
            }
            handleEditTemplate();
          },
        },
    {
      label: 'Remove Document',
      onClick: () => alert('Share action triggered'),
      isDestructive: true,
    },
  ];

  return (
    <article className="flex flex-col p-6 bg-white rounded-md shadow-sm border border-slate-200 min-w-[404px] mr-4">
      <header className="flex w-full items-center gap-4">
        <img
          loading="lazy"
          src={projectLogo}
          alt="Sale card logo"
          className="w-14 rounded-xl aspect-square"
        />
        <div className="flex-1 flex flex-col text-lg font-bold text-slate-900 truncate">
          <h1>{name}</h1>
          {tag && (
            <span className="gap-2 mt-2 self-start px-2 py-0.5 text-xs font-normal rounded-lg bg-zinc-800 text-neutral-100">
              {`--${tag}`}
            </span>
          )}
        </div>
        <button
          aria-label="More options"
          className="w-8 h-8 flex justify-center items-center bg-white rounded-md border border-slate-100"
          onClick={() => setToggleCardMenu(!toggleCardMenu)}
        >
          <img
            loading="lazy"
            src={more}
            alt="More options"
            className="w-4 aspect-square"
          />
        </button>
        {toggleCardMenu && (
          <section className="absolute right-10 top-20">
            <SaleCardMenu project={project} refetchProjects={refetchProjects} />
          </section>
        )}
      </header>

      <section className="mt-6 text-xs flex items-center gap-2">
        <div className="flex items-center gap-1">
          <img
            loading="lazy"
            src={link}
            alt="Chain ID icon"
            className="w-4 aspect-square"
          />
          <span className="text-slate-500">Chain ID:</span>
          <strong className="font-medium text-black">{chainId}</strong>
        </div>
        <div className="flex items-center gap-1">
          <img
            loading="lazy"
            src={flag}
            alt="KYC status icon"
            className="w-4 aspect-square"
          />
          <span className="text-slate-500">KYC Status:</span>
          <strong className="font-medium text-black">
            {kycStatus ? 'true' : 'false'}
          </strong>
        </div>
      </section>

      <section className="mt-6 text-xs">
        <div className="flex gap-4 items-start">
          <img
            loading="lazy"
            src={shopingBag}
            alt="Sale type icon"
            className="w-10 aspect-square"
          />
          <div className="flex flex-col flex-1">
            <span className="text-slate-500">Sale format:</span>
            <strong className="font-medium text-black mt-1">{saleType}</strong>
          </div>
        </div>
        <div className="flex gap-4 items-start mt-4">
          <img
            loading="lazy"
            src={layoutList}
            alt="Unique ID icon"
            className="w-10 aspect-square"
          />
          <div className="flex flex-col flex-1">
            <span className="text-slate-500">Unique ID:</span>
            <strong className="font-medium text-black mt-1">{uniqueId}</strong>
          </div>
        </div>
      </section>

      <section className="mt-6 text-xs">
        <div className="flex flex-col">
          <span className="text-slate-500">Wishlist Date:</span>
          <div className="flex w-full">
            <div className="flex items-center gap-1 mt-1 w-1/2">
              <img
                loading="lazy"
                src={calendar}
                alt="Start date icon"
                className="w-4 aspect-square"
              />
              <span className="text-slate-500">Start:</span>
              <strong className="font-medium text-black">
                {wishlistStart}
              </strong>
            </div>
            <div className="flex gap-1 items-center mt-1 w-1/2">
              <img
                loading="lazy"
                src={target}
                alt="End date icon"
                className="w-4 aspect-square"
              />
              <span className="text-slate-500">End:</span>
              <strong className="font-medium text-black">{wishlistEnd}</strong>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <span className="text-slate-500">Funding Date:</span>
          <div className="flex w-full">
            <div className="flex gap-1 items-center mt-1 w-1/2">
              <img
                loading="lazy"
                src={calendar}
                alt="Start date icon"
                className="w-4 aspect-square"
              />
              <span className="text-slate-500">Start:</span>
              <strong className="font-medium text-black">{fundingStart}</strong>
            </div>
            <div className="flex gap-1 items-center mt-1 w-1/2">
              <img
                loading="lazy"
                src={target}
                alt="End date icon"
                className="w-4 aspect-square"
              />
              <span className="text-slate-500">End:</span>
              <strong className="font-medium text-black">{fundingEnd}</strong>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-6">
        <div className="flex items-center justify-between">
          <div className="text-base font-bold text-slate-900">
            {investedAmount} UDSC
          </div>
          <div className="text-sm font-bold text-slate-900">
            {investedPercentage} invested
          </div>
        </div>
        <div className="mt-2 bg-slate-100 h-4 rounded-full overflow-hidden">
          <div
            className="bg-slate-900 h-full"
            style={{ width: investedPercentage }}
          ></div>
        </div>
        <div className="mt-1 text-sm text-slate-500">
          {remainingAmount} UDSC left
        </div>
      </section>

      <section className="flex flex-col mt-6 text-xs relative">
        <span className="text-slate-500">Uploads</span>
        <div className="flex mt-2 items-center ">
          {templateName ? (
            <div className="flex items-center justify-between w-full">
              <span className="text-sm font-medium">{templateName}</span>
              <button
                className="ml-4 justify-between border rounded-lg p-2 max-w-md"
                onClick={() => setToggleDocumentActions(!toggleDocumentActions)}
              >
                <img src={more} alt="Options" className="h-4 w-4" />
              </button>
            </div>
          ) : !selectedFile ? (
            <>
              <button
                type="button"
                onClick={triggerFileInput}
                className="btn-action font-medium"
              >
                Browse...
              </button>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                className="hidden"
              />
              <span className="ml-4 text-slate-500 font-medium text-sm">
                No file selected.
              </span>
            </>
          ) : (
            <div className="flex items-center justify-between w-full">
              <span className="text-sm font-medium">{selectedFile.name}</span>
              <button
                className="ml-4 justify-between border rounded-lg p-2 max-w-md"
                onClick={() => setToggleDocumentActions(!toggleDocumentActions)}
              >
                <img src={more} alt="Options" className="h-4 w-4" />
              </button>
            </div>
          )}
        </div>

        {toggleDocumentActions && <DocumentActions actions={documentActions} />}
      </section>

      <hr className="w-full border-slate-200 my-6" />

      {!allStepsDone && (
        <section className="flex gap-2 w-full">
          {toggleTooltip && (
            <section className=" absolute top-[496px]">
              <TooltipMessage
                message={
                  editStepStatus === 'done'
                    ? 'Complete and save the embed information to access.'
                    : 'Complete and save the edit information to access.'
                }
              />
            </section>
          )}
          <button
            className={
              editStepStatus === 'done' ? 'btn-action' : 'btn-action-required'
            }
            onClick={openEditModal}
          >
            <span>
              <img
                loading="lazy"
                src={
                  editStepStatus === 'done'
                    ? editBlack
                    : editStepStatus === 'actionReq'
                      ? editWhite
                      : edit
                }
                alt="Edit icon"
                className="w-4 aspect-square"
              />
            </span>
            {`1. Edit`}
          </button>
          <button
            className={
              embedStepStatus === 'done'
                ? 'btn-action'
                : embedStepStatus === 'actionReq'
                  ? 'btn-action-required'
                  : 'btn-not-allowed'
            }
            onMouseEnter={() =>
              embedStepStatus === 'prevComplete' && setToggleTooltip(true)
            }
            onMouseLeave={() =>
              embedStepStatus === 'prevComplete' && setToggleTooltip(false)
            }
            onClick={() => {
              const chainId = Number(project.chain);
              const isTestnet =
                chainId === 4002 ||
                chainId === 80002 ||
                chainId === 97 ||
                chainId === 5 ||
                chainId === 421611 ||
                chainId === 420 ||
                chainId === 43113;
              const isINO = project.sale_version === 3;
              window.open(
                `https://embed.futurestarter.xyz/?path=/story/pages-rwaprojectpage--default&args=id:${project.id};isTestnet:${isTestnet};isINO:${isINO}`,
                '_blank'
              );
              localStorage.setItem(`embedStepDone-${project.id}`, 'true');
              setRefetchLocalStorageData(
                `refetchLocalStorageData - ${Date.now().toString()}`
              );
            }}
          >
            <span>
              <img
                loading="lazy"
                src={
                  embedStepStatus === 'done'
                    ? codeBlack
                    : embedStepStatus === 'actionReq'
                      ? codeWhite
                      : code
                }
                alt="Embed icon"
                className="w-4 aspect-square"
              />
            </span>
            {`2. Embed`}
          </button>
          <button
            className={
              deployStepStatus === 'done'
                ? 'btn-action'
                : deployStepStatus === 'actionReq'
                  ? 'btn-action-required'
                  : 'btn-not-allowed'
            }
            onMouseEnter={() =>
              deployStepStatus === 'prevComplete' && setToggleTooltip(true)
            }
            onMouseLeave={() =>
              deployStepStatus === 'prevComplete' && setToggleTooltip(false)
            }
            onClick={() =>
              deployStepStatus !== 'prevComplete' && openDeployModal()
            }
          >
            <span>
              <img
                loading="lazy"
                src={
                  deployStepStatus === 'done'
                    ? truckBlack
                    : deployStepStatus === 'actionReq'
                      ? truckWhite
                      : truck
                }
                alt="Deploy icon"
                className="w-4 aspect-square"
              />
            </span>
            {`3. Deploy`}
          </button>
        </section>
      )}

      {allStepsDone && (
        <div className={`font-inter w-full flex flex-col gap-2 `}>
          <p className="text-xs text-slate-500">Available Actions</p>
          <div className="w-full flex flex-wrap items-center justify-center gap-2 min-[342px]:flex-nowrap">
            <div
              className="flex items-center gap-2 border border-slate-300 rounded-md px-4 py-2 cursor-pointer whitespace-nowrap text-ellipsis"
              onClick={() => setToggleActionDropdown(!toggleActionDropdown)}
            >
              <span className="w-28 text-slate-900">
                {selectedAction ? selectedAction : 'Select action'}
              </span>
              <img
                src={arrowDown}
                alt="arrow down"
                className="w-4 aspect-square cursor-pointer"
              />
            </div>
            <button
              className={`w-1/2 rounded-md bg-slate-700 px-8 py-2 text-white ${isExecutingAction && 'cursor-wait'}`}
              onClick={handleGoClick}
              disabled={isExecutingAction}
            >
              {isExecutingAction ? 'Please wait...' : 'Go'}
            </button>
          </div>
        </div>
      )}

      {toggleActionDropdown && (
        <div
          className={`absolute ${isRetrieveFundStepDone ? 'top-[416px]' : 'top-96'} left-32 w-64 flex flex-col-reverse items-start rounded-md shadow-lg`}
        >
          <div className="flex w-full flex-col overflow-hidden rounded-md border border-slate-100">
            <div className="flex flex-col gap-3 bg-white px-4 py-3 text-sm font-medium text-slate-700">
              <div
                className={`cursor-pointer`}
                onClick={() => {
                  handleActionSelect('Edit');
                  setToggleActionDropdown(false);
                }}
              >
                Edit
              </div>
              <div
                className={`cursor-pointer`}
                onClick={() => {
                  handleActionSelect('Embed');
                  setToggleActionDropdown(false);
                }}
              >
                Embed
              </div>
              <div
                className={`cursor-pointer`}
                onClick={() => {
                  handleActionSelect('Deploy');
                  setToggleActionDropdown(false);
                }}
              >
                Deploy
              </div>
              {!isFundToContractStepDone && (
                <div
                  className={`cursor-pointer`}
                  onClick={() => {
                    handleActionSelect('Fund Contract');
                    setToggleActionDropdown(false);
                  }}
                >
                  Fund Contract
                </div>
              )}
              {isFundToContractStepDone && !isEnableClaimingStepDone && (
                <div
                  className={`cursor-pointer`}
                  onClick={() => {
                    handleActionSelect('Enable Claiming');
                    setToggleActionDropdown(false);
                  }}
                >
                  Enable Claiming
                </div>
              )}
              {isFundToContractStepDone &&
                isEnableClaimingStepDone &&
                !isRetrieveFundStepDone && (
                  <div
                    className={`cursor-pointer`}
                    onClick={() => {
                      handleActionSelect('Retrieve Balance');
                      setToggleActionDropdown(false);
                    }}
                  >
                    Retrieve Balance
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </article>
  );
};

export default SaleCard;
