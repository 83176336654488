import React from 'react';

interface FundsOverviewProps {
  totalBalance: string;
  smartContractBalance: string;
  mainAccountBalance: string;
}

const FundsOverview: React.FC<FundsOverviewProps> = ({
  totalBalance,
  smartContractBalance,
  mainAccountBalance,
}) => {
  return (
    <section className="p-6 bg-white rounded-md border border-slate-300 w-full">
      <header className="flex justify-between items-center w-full">
        <h1 className="text-lg font-semibold text-slate-900">Funds Overview</h1>
        <nav className="flex gap-2">
          <button className="px-4 py-2 bg-slate-100 rounded-md text-slate-900">
            Withdraw from Smart Contracts
          </button>
          <button className="px-4 py-2 bg-slate-900 text-white rounded-md">
            Withdraw to Bank Account
          </button>
        </nav>
      </header>

      <div className="mt-6">
        <p className="text-3xl font-bold text-slate-900">{totalBalance} USDC</p>
      </div>

      <div className="mt-4 w-full">
        <div className="flex w-[500px]">
          <div className="flex-1 h-3 bg-sky-500 rounded-l-md" />
          <div className="flex-1 h-3 bg-fuchsia-500 rounded-r-md" />
        </div>

        <div className="flex gap-4 items-center mt-2">
          <div className="flex items-center">
            <div className="w-3 h-3 bg-sky-500 rounded-full" />
            <p className="ml-2 text-xs text-slate-500">
              Smart Contracts: {smartContractBalance} USDC
            </p>
          </div>

          <div className="flex items-center">
            <div className="w-3 h-3 bg-fuchsia-500 rounded-full" />
            <p className="ml-2 text-xs text-slate-500">
              Main Account: {mainAccountBalance} USDC
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FundsOverview;
