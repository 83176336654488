import { FC } from 'react';

interface IWithdrawDetailsPopoverProps {
  transactionId: string;
  asset: string;
  amount: string;
  fee: string;
  total: string;
  date: string;
  onClose: () => void;
}

const WithdrawDetailsPopover: FC<IWithdrawDetailsPopoverProps> = ({
  transactionId,
  asset,
  amount,
  fee,
  total,
  date,
  onClose,
}) => {
  return (
    <div className="absolute left-96 min-w-[320px] p-4 bg-white border border-slate-200 rounded-md shadow-lg text-sm font-inter">
      <div className="font-medium text-lg">Withdraw {transactionId}</div>
      <div className="text-slate-400 mt-2 mb-6">Withdraw details</div>

      <div className="flex flex-col gap-6">
        <PopoverItem label="Asset" value={asset} />
        <PopoverItem label="Amount" value={amount} />
        <PopoverItem label="Fee" value={fee} />
        <PopoverItem label="Total" value={total} />
        <PopoverItem label="Date" value={date} />
      </div>

      <div className="mt-4 flex justify-end">
        <button
          className="px-4 py-2 bg-slate-900 text-white rounded-md"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const PopoverItem: FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <div className="flex justify-between">
    <div className="font-medium text-slate-600">{label}</div>
    <div className="font-semibold text-slate-900">{value}</div>
  </div>
);

export default WithdrawDetailsPopover;
