import React from 'react';
import { gmailLogo, appleLogo } from '../../assets';

interface IAuthButtonsProps {
  onContinueWithGoogle: () => void;
  onContinueWithApple: () => void;
}

const AuthButtons: React.FC<IAuthButtonsProps> = ({
  onContinueWithGoogle,
  onContinueWithApple,
}) => (
  <section className="w-full max-w-[360px] mx-auto mt-8">
    <button
      className="btn-text w-full rounded-[6px] border border-[#E2E8F0]"
      onClick={onContinueWithGoogle}
    >
      <img src={gmailLogo} alt="gmail-logo" className="w-4 h-4 mr-2" />
      Continue with Google
    </button>
    <button
      className="btn-text w-full mt-4 rounded-[6px] border border-[#E2E8F0]"
      onClick={onContinueWithApple}
    >
      <img src={appleLogo} alt="apple-logo" className="w-4 h-4 mr-2" />
      Continue with Apple
    </button>
  </section>
);

export default AuthButtons;
