import React, { useState } from 'react';
import BillingAddressForm from './BillingAddress';
import BankAccountForm from './BankAccount';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import type { BillingDetails } from './BillingAddress';
import type { BankDetails } from './BankAccount';
import { billingDetailsSchema } from './BillingAddress';
import { bankDetailsSchema } from './BankAccount';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';

interface IAccountLinkingFormProps {
  onCancel: () => void;
}

const AccountLinkingForm: React.FC<IAccountLinkingFormProps> = ({
  onCancel,
}) => {
  const [step, setStep] = useState<number>(1);
  const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(
    null
  );
  const [idempotencyKey] = useState<string>(uuidv4());
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [success, setSuccess] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    circle: { linkWireBankAccount },
  } = useFantomStarterAdminApi();

  const handleBillingSave = (billingData: BillingDetails) => {
    setBillingDetails(billingData);
    setStep(2);
  };

  const handleBankSave = async (bankData: BankDetails) => {
    setIsSubmitting(true);
    setErrors({});
    setSuccess('');

    try {
      if (!billingDetails) {
        throw new Error('Billing details are missing.');
      }

      billingDetailsSchema.parse(billingDetails);
      bankDetailsSchema.parse(bankData);

      const response = await linkWireBankAccount({
        idempotencyKey,
        accountNumber: bankData.accountNumber,
        routingNumber: bankData.routingNumber,
        billingDetails,
        bankAddress: {
          bankName: bankData.bankName,
          city: bankData.city,
          country: bankData.country,
          line1: bankData.line1,
          line2: bankData.line2,
          district: bankData.district,
        },
      });

      if (response.status === 201) {
        setSuccess('Bank account linked successfully!');
      } else {
        throw new Error('Failed to link bank account.');
      }
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        const validationErrors: Record<string, string> = {};
        err.errors.forEach((error) => {
          if (error.path.length > 0) {
            const fieldName = error.path[0];
            validationErrors[fieldName as string] = error.message;
          }
        });
        setErrors(validationErrors);
      } else {
        setErrors({ submit: err.message || 'An unexpected error occurred.' });
      }
    }

    setIsSubmitting(false);
  };

  return (
    <div className="z-50">
      {step === 1 && (
        <BillingAddressForm onSave={handleBillingSave} onCancel={onCancel} />
      )}
      {step === 2 && (
        <BankAccountForm
          onSave={handleBankSave}
          onCancel={() => setStep(1)}
          errors={errors}
          isSubmitting={isSubmitting}
          success={success}
        />
      )}
    </div>
  );
};

export default AccountLinkingForm;
