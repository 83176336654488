import React, { useState, useRef, useCallback } from 'react';
import { extractDateMonthAndTime } from '../../utils/conversion';
import {
  cross,
  checkedRadio,
  uncheckedRadio,
  tag,
  layoutList,
  calendarBig,
} from '../../assets';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import useERC20Method from '../../hooks/useERC20ContractMethod';
import { toast } from 'react-toastify';

interface SaleTypeOption {
  label: string;
  checked: boolean;
  icon?: string;
}

interface DeployContractProps {
  onClose: () => void;
  project: any;
}

const DeployModal: React.FC<DeployContractProps> = ({ onClose, project }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const defaultLiquidityFactor = 0.5;

  const name = project.name;
  const uniqueId = project.id;
  const startDate = extractDateMonthAndTime(project.start_date_funding);
  const endDate = extractDateMonthAndTime(project.end_date_funding);
  const isAlreadyDeployed = project.sale_contract_address;

  const [saleTypes, setSaleTypes] = useState<SaleTypeOption[]>([
    {
      label: 'Open',
      checked: !project.with_whitelist && !project.with_lottery ? true : false,
      icon: '',
    },
    {
      label: 'Registered',
      checked: project.with_whitelist ? true : false,
      icon: '',
    },
    {
      label: 'Auction sale',
      checked: project.with_lottery ? true : false,
      icon: '',
    },
  ]);

  const [pricePerToken, setPricePerToken] = useState<number>(
    project.sale_version === 8 || project.sale_version === 10
      ? Number(project.ratio_paytoken_per_idotoken) * defaultLiquidityFactor
      : Number(project.ratio_paytoken_per_idotoken)
  );

  const [maxInvestment, setMaxInvestment] = useState<number>(
    Number(project.cap_individual_dollars)
  );

  const [totalRaiseGoal, setTotalRaiseGoal] = useState<number>(
    Number(project.cap_total_dollars)
  );

  const [isDeploying, setIsDeploying] = useState(false);

  const {
    admin: { deployContractsForProject },
  } = useFantomStarterAdminApi();
  const { getDecimals } = useERC20Method(project?.chain || 4002);

  const handleNumberInputChange =
    (setter: React.Dispatch<React.SetStateAction<number>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setter(value === '' ? 0 : Number(value));
    };

  const handleCheckboxChange = (index: number): void => {
    setSaleTypes((prevSaleTypes) =>
      prevSaleTypes.map((type, i) => ({
        ...type,
        checked: i === index,
      }))
    );
  };

  const handleLaunchContract = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (isAlreadyDeployed) return;
      setIsDeploying(true);

      const decimalFactorINOSale = 10000;
      const isINO = project.sale_version === 3 || project.sale_version === 5;

      if (!isINO) {
        toast.error('Sale version not supported', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setIsDeploying(false);
        return;
      }

      const defaultPayTokenAddress =
        '0x41E94Eb019C0762f9Bfcf9Fb1E58725BfB0e7582';
      const inoTokenAddress = '0x2A00Fed743Cc5312BA88D009c6135C096A6784a3';

      let payTokenDecimals = 6;

      payTokenDecimals = await getDecimals(defaultPayTokenAddress);

      if (!payTokenDecimals) {
        toast.error('Failed to retrieve contract data', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setIsDeploying(false);
        return;
      }

      const selectedSaleType = saleTypes.find((type) => type.checked)?.label;
      let saleType = 'fcfs';
      if (selectedSaleType === 'Registered') {
        saleType = 'whitelist';
      } else if (selectedSaleType === 'Auction sale') {
        saleType = 'lottery';
      }

      const deploymentData = {
        id: project.id,
        tokens_for_sale: totalRaiseGoal,
        price_per_item: pricePerToken * decimalFactorINOSale,
        start_date_funding: project.start_date_funding,
        end_date_funding: project.end_date_funding,
        sale_type: saleType,
        kyc_required: project.with_kyc ? 'yes' : 'no',
        pay_token_address: defaultPayTokenAddress,
        ico_token_address: inoTokenAddress,
        pay_token_decimals: payTokenDecimals,
        stake_required: project.only_staked ? 'yes' : 'no',
        chain: project.chain,
      };
      try {
        const response = await deployContractsForProject(deploymentData, 'ino');
        if (response.data.result.err) {
          toast.error('Deployment failed: ' + response.data.result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.success('Contract deployed successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          onClose();
          localStorage.setItem(`deployStepDone-${project.id}`, 'true');
        }
      } catch (error) {
        console.error('Error during contract deployment:', error);
        toast.error('An error occurred during deployment', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } finally {
        setIsDeploying(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project, pricePerToken, totalRaiseGoal, saleTypes]
  );

  const handleCancel = () => {
    onClose();
  };

  const saleDetails = [
    {
      label: 'Name',
      value: name,
      icon: tag,
    },
    {
      label: 'Unique ID',
      value: uniqueId,
      icon: layoutList,
    },
    {
      label: 'Sale Date',
      value: (
        <div className="flex justify-between w-full">
          <div className="w-1/2">
            <span>Start: </span>
            <span className="font-medium">{startDate}</span>
          </div>
          <div className="w-1/2">
            <span>End: </span>
            <span className="font-medium">{endDate}</span>
          </div>
        </div>
      ),
      icon: calendarBig,
    },
  ];

  return (
    <>
      <div>
        <div className="flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto">
          <div className="relative w-[600px] my-6 mx-auto">
            <section className="flex flex-col p-6 bg-white rounded-md shadow-sm max-w-[600px]">
              <header className="w-full">
                <div className="flex justify-center items-center text-2xl font-semibold text-slate-900">
                  <h1 className="flex-1 font-semibold leading-8">
                    Deploy Smart Contract
                  </h1>
                  <img
                    src={cross}
                    className="w-6 cursor-pointer"
                    alt="cross"
                    onClick={() => onClose()}
                  />
                </div>
                <p className="mt-2 text-base text-slate-600 font-normal leading-7">
                  Configure the necessary details to launch the smart contract
                  for your sale.
                </p>
              </header>

              <section className="flex flex-col gap-4 mt-6 w-full text-sm text-black">
                {saleDetails.map((detail, index) => (
                  <div key={index} className="flex gap-4 w-full">
                    <img
                      loading="lazy"
                      src={detail.icon}
                      alt=""
                      className="object-contain w-10"
                    />
                    <div className="flex-1">
                      <div className="font-medium leading-5">
                        {detail.label}
                      </div>
                      <div>{detail.value}</div>
                    </div>
                  </div>
                ))}
              </section>

              <form
                className="flex flex-col mt-4"
                onSubmit={handleLaunchContract}
              >
                <div className="flex flex-row w-full text-sm h-[42px] items-center justify-between">
                  <label className="font-medium">Sale format</label>
                  <div className="flex gap-2">
                    {saleTypes.map((type, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() => handleCheckboxChange(index)}
                      >
                        <img
                          src={type.checked ? checkedRadio : uncheckedRadio}
                          alt={type.checked ? 'Checked' : 'Unchecked'}
                          className="w-4 h-4"
                        />
                        <label
                          htmlFor={`sale-type-${index}`}
                          className="flex items-center gap-2"
                        >
                          <span>{type.label}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {[
                  {
                    label: 'Price per unit',
                    value: pricePerToken,
                    setter: setPricePerToken,
                  },
                  {
                    label: 'Maximum investment',
                    value: maxInvestment,
                    setter: setMaxInvestment,
                  },
                  {
                    label: 'Total raise goal',
                    value: totalRaiseGoal,
                    setter: setTotalRaiseGoal,
                  },
                ].map(({ label, value, setter }) => (
                  <div key={label} className="flex items-center mt-4">
                    <div className="flex-1 text-sm font-medium text-black">
                      {label}
                    </div>
                    <input
                      type="number"
                      value={value}
                      onChange={handleNumberInputChange(setter)}
                      className="py-2 px-3 min-w-[272px] bg-white rounded-md border border-slate-300 outline-none"
                      aria-label={label}
                    />
                  </div>
                ))}

                <div className="flex justify-between mt-6 border-t-[1px] pt-4">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="btn-text w-1/2"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`btn-primary w-1/2 ${
                      isDeploying
                        ? 'opacity-50 cursor-wait'
                        : isAlreadyDeployed
                          ? 'opacity-50 cursor-not-allowed'
                          : ''
                    }`}
                    disabled={isDeploying || isAlreadyDeployed}
                  >
                    {isDeploying
                      ? 'Deploying...'
                      : isAlreadyDeployed
                        ? 'Smart Contract Deployed'
                        : 'Launch Smart Contract'}
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
        <div
          ref={modalRef}
          className="fixed inset-0 z-40 bg-black bg-opacity-70 backdrop-blur-[2px]"
        />
      </div>
    </>
  );
};

export default DeployModal;
