import React, { RefObject } from 'react';
import { calendar } from '../../assets';

const SaleCalendarCard: React.FC<{
  startDate: string | null;
  endDate: string | null;
  errors: Record<string, string>;
  handleDateChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string | null>>
  ) => void;
  setStartDate: React.Dispatch<React.SetStateAction<string | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<string | null>>;
  startDateInputRef: RefObject<HTMLInputElement>;
  endDateInputRef: RefObject<HTMLInputElement>;
}> = ({
  startDate,
  endDate,
  errors,
  handleDateChange,
  setStartDate,
  setEndDate,
  startDateInputRef,
  endDateInputRef,
}) => {
  const handleDateClick = (inputRef: RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  };

  return (
    <section className="flex flex-col px-6 py-4 mt-6 w-full bg-white rounded-md border border-[#CBD5E1]">
      <header className="w-full text-sm font-semibold text-black">
        <h2 className="text-lg">Sale Calendar</h2>
        <hr className="mt-4 w-full border-slate-200" aria-hidden="true" />
      </header>
      <div className="flex flex-col mt-8 w-full">
        <div className="flex gap-4 items-center w-full">
          <div
            className={`flex flex-col items-center justify-center py-6 bg-white rounded-md border max-w-[206px] flex-1 cursor-pointer hover:bg-gray-50 transition-colors duration-200 ${
              errors.startDate ? 'border-red-500' : 'border-[#CBD5E1]'
            }`}
            onClick={() => handleDateClick(startDateInputRef)}
          >
            <img
              loading="lazy"
              src={calendar}
              alt="Start date icon"
              className="w-6 aspect-square"
            />
            <div className="mt-1 text-base font-medium text-black">
              Start Date
            </div>
            <input
              type="date"
              value={startDate || ''}
              onChange={(e) => handleDateChange(e, setStartDate)}
              className="h-0 w-0 opacity-0 absolute"
              ref={startDateInputRef}
            />
            <div className="mt-2 text-xs text-[#334155]">
              {startDate === '' || startDate === null
                ? 'No date selected'
                : startDate}
            </div>
          </div>
          <div
            className={`flex flex-col items-center justify-center py-6 bg-white rounded-md border max-w-[206px] flex-1 cursor-pointer hover:bg-gray-50 transition-colors duration-200 ${
              errors.endDate ? 'border-red-500' : 'border-[#CBD5E1]'
            }`}
            onClick={() => handleDateClick(endDateInputRef)}
          >
            <img
              loading="lazy"
              src={calendar}
              alt="End date icon"
              className="w-6 aspect-square"
            />
            <div className="mt-1 text-base font-medium text-black">
              End Date
            </div>
            <input
              type="date"
              value={endDate || ''}
              onChange={(e) => handleDateChange(e, setEndDate)}
              className="h-0 w-0 opacity-0 absolute"
              ref={endDateInputRef}
            />
            <div className="mt-2 text-xs text-[#334155]">
              {endDate === '' || endDate === null
                ? 'No date selected'
                : endDate}
            </div>
          </div>
        </div>
        {errors.startDate && (
          <p className="text-red-500 text-xs mt-1">{errors.startDate}</p>
        )}
        {errors.endDate && (
          <p className="text-red-500 text-xs mt-1">{errors.endDate}</p>
        )}
      </div>
    </section>
  );
};

export default SaleCalendarCard;
