const theme = {
  colors: {
    font: {
      1: 'black',
      2: 'grey',
      3: 'white',
    },
    primary: {
      1: `#F3F3F3`, // #grey,
      2: `#0C1727`, // #black
      3: `#765cdf`, // #purple,
      4: `#DF73EC`, // #pink,
      5: `#FCBA57`, // #orange,
    },
    secondary: {
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
    },
  },
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
};

export default theme;
