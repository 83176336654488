import React from 'react';

interface ProjectCardProps {
  name: string;
  amount: string;
  imageSrc: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  name,
  amount,
  imageSrc,
}) => {
  return (
    <article className="flex flex-col self-stretch my-auto text-sm rounded-none min-w-[240px] w-[328px]">
      <div className="flex flex-col w-full">
        <img
          loading="lazy"
          src={imageSrc}
          alt={`${name} project visualization`}
          className="object-contain w-fullrounded-t-md rounded-b-none aspect-[0.85]"
        />
        <div className="flex flex-col justify-center items-start p-3 w-full bg-white rounded-b-md rounded-t-none border-r border-b border-l border-slate-100">
          <div className="flex flex-col max-w-full w-[227px]">
            <h2 className="font-medium leading-none text-black">{name}</h2>
            <p className="mt-1 leading-none text-slate-500">{amount}</p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ProjectCard;
