import { useEffect, useRef, useState } from 'react';
import { DesktopLayout } from '../../components/Layouts';
import { FundsOverview } from '../../components/FundsOverview';
import { Pagination } from '../../components/Pagination';
import { WithdrawDetailsPopover } from '../../components/Popover';
import { search, filter, arrowDown } from '../../assets';

interface ISaleDetailItem {
  label: string;
  value: string;
}

interface ISaleTableData {
  customer: string;
  saleId: string;
  saleType: string;
  status: string;
  saleDate: string;
  saleFees: string;
  saleAmount: string;
  detailItems: ISaleDetailItem[];
}

interface IWithdrawTableData {
  transaction: string;
  transactionId: string;
  transactionType: string;
  transactionDate: string;
  fee: string;
  amount: string;
  status: string;
}

export default function Financials() {
  const salesHeaders = [
    'Customer',
    'Sale ID',
    'Sale Type',
    'Status',
    'Sale Date',
    'Sale Fees',
    'Sale Amount',
  ];

  const withdrawHeaders = [
    'Transaction',
    'Transaction ID',
    'Transaction Type',
    'Transaction Date',
    'Fee',
    'Amount',
    'Status',
  ];

  const saleTableData: ISaleTableData[] = [
    {
      customer: 'John Smith',
      saleId: '29c31076-f5cf-4b65-9001-0ab262c6260b',
      saleType: 'Open',
      status: 'Sent',
      saleDate: '3/26/2024',
      saleFees: '$ 24.00',
      saleAmount: '$ 24,000.00',
      detailItems: [
        { label: 'Sale Name', value: 'Alux 33' },
        { label: 'Fee Name', value: '$ 24.00' },
        { label: 'Fee Name', value: '$ 24.00' },
        { label: 'Withdraw Date', value: 'N/A' },
      ],
    },
    {
      customer: 'John Smith',
      saleId: '29c31076-f5cf-4b65-9001-0ab262c6260b',
      saleType: 'Open',
      status: 'Sent',
      saleDate: '3/26/2024',
      saleFees: '$ 24.00',
      saleAmount: '$ 24,000.00',
      detailItems: [
        { label: 'Sale Name', value: 'Alux 33' },
        { label: 'Fee Name', value: '$ 24.00' },
        { label: 'Fee Name', value: '$ 24.00' },
        { label: 'Withdraw Date', value: 'N/A' },
      ],
    },
  ];

  const withdrawTableData: IWithdrawTableData[] = [
    {
      transaction: 'Withdraw ETH',
      transactionId: '43523',
      transactionType: 'Bank Account',
      transactionDate: '27/05/2024 5:33 PM',
      fee: '$ 24.00',
      amount: '$ 24,000.00',
      status: 'Pending',
    },
    {
      transaction: 'Withdraw ETH',
      transactionId: '43524',
      transactionType: 'Bank Account',
      transactionDate: '27/05/2024 5:33 PM',
      fee: '$ 24.00',
      amount: '$ 24,000.00',
      status: 'Completed',
    },
  ];

  const headerRef = useRef<HTMLDivElement | null>(null);
  const [columnWidth, setColumnWidth] = useState<number>(0);
  const [toogleDetail, setToogleDetail] = useState<boolean>(false);
  const [toggleDetailIndex, setToggleDetailIndex] = useState<number>(-1);
  const [tabs, setTabs] = useState([
    { label: 'Sales History', isActive: true },
    { label: 'Withdraw History ', isActive: false },
  ]);

  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [popoverTransactionId, setPopoverTransactionId] = useState<
    string | null
  >(null);

  const handleTabClick = (index: number) => {
    const updatedTabs = tabs.map((tab, i) => ({
      ...tab,
      isActive: i === index,
    }));
    setTabs(updatedTabs);
    setPopoverVisible(false);
    setPopoverTransactionId(null);
  };

  const handleDetailClick = (index: number) => {
    toogleDetail && toggleDetailIndex === index
      ? setToogleDetail(!toogleDetail)
      : setToogleDetail(true);
    setToggleDetailIndex(index);
  };

  const handleTransactionClick = (transactionId: string) => {
    if (popoverVisible) {
      setPopoverVisible(false);
      setPopoverTransactionId(null);
      return;
    }
    setPopoverTransactionId(transactionId);
    setPopoverVisible(true);
  };

  const closePopover = () => {
    setPopoverVisible(false);
    setPopoverTransactionId(null);
  };

  useEffect(() => {
    if (headerRef.current) {
      const width = headerRef.current.getBoundingClientRect().width;
      setColumnWidth(width);
    }
  }, []);

  return (
    <DesktopLayout>
      <main className="pt-24 sm:px-32 px-5 flex flex-col items-start">
        <FundsOverview
          totalBalance="120000.00"
          smartContractBalance="100000"
          mainAccountBalance="20000"
        />

        <nav className="flex py-1.5 px-1.5 mt-10 rounded-md bg-slate-100 text-slate-700">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={`px-3 py-1.5 rounded ${
                tab.isActive ? 'bg-white text-slate-900' : 'text-slate-700'
              }`}
            >
              {tab.label}
            </button>
          ))}
        </nav>

        <nav className="flex items-center justify-end gap-4 mt-4 py-2 bg-white w-full">
          <div className="flex items-center gap-2 px-2 py-2.5 w-[280px] text-sm leading-none border rounded-md shadow-sm">
            <img
              loading="lazy"
              src={search}
              alt=""
              className="w-4 h-4 object-contain"
            />
            <input
              type="text"
              placeholder="Search a sale ID"
              className="flex-1 text-slate-400 bg-transparent border-none outline-none placeholder-opacity-50"
              aria-label="Search a sale ID"
            />
          </div>

          <button className="flex justify-center items-center p-2 bg-white border rounded-md">
            <img
              loading="lazy"
              src={filter}
              alt={filter}
              className="w-4 h-4 object-contain mr-2"
            />
            Filter
          </button>

          <button className="btn-action p-3">Export.CVS</button>

          <button className="btn-primary">Start a sale</button>
        </nav>

        {tabs[0].isActive ? (
          <section className="w-full overflow-x-auto">
            <div className="grid grid-cols-[56px_repeat(7,1fr)] gap-0 bg-slate-50 w-full text-sm font-semibold text-slate-900 whitespace-nowrap">
              <div />

              {salesHeaders.map((header, index) => (
                <div
                  key={index}
                  ref={index === 0 ? headerRef : null}
                  className={`p-4 cursor-pointer flex justify-between items-centerbg-slate-50`}
                >
                  {header}
                </div>
              ))}
            </div>

            <section className="w-full">
              {saleTableData.map((row, index) => (
                <section key={index}>
                  <section
                    className={`grid grid-cols-[56px_repeat(7,1fr)] gap-0 bg-white w-full text-sm whitespace-nowrap ${toggleDetailIndex !== index && 'border-b'}`}
                  >
                    <div
                      className="h-full flex items-center cursor-pointer"
                      onClick={() => handleDetailClick(index)}
                    >
                      <img
                        loading="lazy"
                        src={arrowDown}
                        alt="arrow-down"
                        className="w-4 aspect-square mx-auto"
                      />
                    </div>

                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.customer}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.saleId}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.saleType}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.status}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.saleDate}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.saleFees}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.saleAmount}
                    </div>
                  </section>

                  {toogleDetail && toggleDetailIndex === index && (
                    <section className="flex flex-wrap items-start pl-12 text-sm max-md:pl-5 border-b border-slate-200">
                      <div className="flex flex-col flex-1 min-w-[240px] max-md:w-full">
                        <div className="border-t border-slate-200" />
                        <div className="flex flex-col px-4 w-full">
                          {row.detailItems.map((item, index) => (
                            <div
                              key={index}
                              className="flex flex-wrap gap-4 py-2 w-full"
                            >
                              <div className="text-neutral-900 w-[140px]">
                                {item.label}:
                              </div>
                              <div className="text-right text-neutral-900">
                                {item.value}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  )}
                </section>
              ))}
            </section>
            <Pagination />
          </section>
        ) : (
          <section className="w-full overflow-x-auto">
            <div className="grid grid-cols-7 gap-0 bg-slate-50 w-full text-sm font-semibold text-slate-900 whitespace-nowrap">
              {withdrawHeaders.map((header, index) => (
                <div
                  key={index}
                  ref={index === 0 ? headerRef : null}
                  className={`p-4 cursor-pointer flex justify-between items-centerbg-slate-50`}
                >
                  {header}
                </div>
              ))}
            </div>

            <section className="w-full">
              {withdrawTableData.map((row, index) => (
                <section key={index}>
                  <section
                    className={`grid grid-cols-7 gap-0 bg-white w-full text-sm whitespace-nowrap ${toggleDetailIndex !== index && 'border-b'}`}
                  >
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.transaction}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis underline cursor-pointer`}
                      onClick={() => handleTransactionClick(row.transactionId)}
                    >
                      {row.transactionId}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.transactionType}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.transactionDate}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.fee}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.amount}
                    </div>
                    <div
                      className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                    >
                      {row.status}
                    </div>
                  </section>

                  {popoverVisible &&
                    popoverTransactionId === row.transactionId && (
                      <WithdrawDetailsPopover
                        transactionId={row.transactionId}
                        asset="USD"
                        amount={row.amount}
                        fee={row.fee}
                        total={`$ ${parseFloat(row.amount.replace('$', '')) + parseFloat(row.fee.replace('$', ''))}`}
                        date={row.transactionDate}
                        onClose={closePopover}
                      />
                    )}
                </section>
              ))}
            </section>
            <Pagination />
          </section>
        )}
      </main>
    </DesktopLayout>
  );
}
