import React from 'react';
import { g8wyAuthLogo } from '../../assets';

const LogoSection: React.FC = () => (
  <section className="bg-[#F8FAFC] w-full sm:w-3/5 font-merriweather h-full flex flex-col justify-center">
    <div>
      <div className="flex flex-col items-center">
        <img
          src={g8wyAuthLogo}
          alt="g8wy-auth-logo"
          className="object-contain w-[480px]"
        />
        <h6 className="text-3xl font-light">Secure. Transparent. Global.</h6>
      </div>
      <h5 className="text-center text-5xl font-light mt-20">
        Real Estate
        <br />
        <span className="font-black">Unreal Speed</span>
      </h5>
    </div>
  </section>
);

export default LogoSection;
