import { xLogo, discordLogo, telegramLogo } from '../../assets';

export const footerColumns = [
  {
    title: 'Products',
    items: [
      'Luxury Real Estate',
      'Vacation Homes',
      'Single-Family Homes',
      'Retail Real Estate',
    ],
    links: ['/sales/create', '/sales/create', '/sales/create', '/sales/create'],
  },
  {
    title: 'Company',
    items: ['FAQ', 'Contact', 'Careers'],
    links: [
      'https://docs.g8wy.app', // External link
      'mailto:hi@g8wy.app',
      'https://angel.co/company/fsmultichain/', // External link
    ],
  },
  {
    title: 'Legal',
    items: ['Terms & Conditions', 'Privacy Policy'],
    links: [
      'https://www.g8wy.app/terms-and-conditions',
      'https://www.g8wy.app/privacy-policy',
    ],
  },
];

export const socialIcons = [
  {
    src: xLogo,
    alt: 'x',
    link: 'https://x.com/g8wy-app',
  },
  {
    src: discordLogo,
    alt: 'discord',
    link: 'https://discord.gg/g8wy-app/',
  },
  {
    src: telegramLogo,
    alt: 'telegram',
    link: 'https://t.me/g8wy-app',
  },
];