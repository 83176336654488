import React from 'react';
import { arrowLeft, arrowRight } from '../../assets';

interface NavigationButtonProps {
  direction: 'left' | 'right';
  onClick: () => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  direction,
  onClick,
}) => {
  const imageSrc = direction === 'left' ? arrowLeft : arrowRight;
  const altText = `Navigate ${direction}`;

  return (
    <button
      className="flex gap-2 justify-center items-center self-stretch p-2 my-auto w-8 h-8 rounded-3xl border border-solid border-slate-200 hover:bg-slate-100 transition-colors focus:outline-none"
      aria-label={altText}
      onClick={onClick}
    >
      <img
        loading="lazy"
        src={imageSrc}
        alt=""
        className="object-contain self-stretch my-auto w-4 aspect-square"
      />
    </button>
  );
};

export default NavigationButton;
