import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import debounce from 'lodash.debounce';

import useAllProjects from '../../hooks/useAllProjects';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import useModal from '../../hooks/useModal';
import { DesktopLayout } from '../../components/Layouts';
import { NoSaleFound } from '../../components/NoSaleFound';
import { SaleCard } from '../../components/Cards';
import { AccountLinkingForm } from '../../components/Form';
import { search } from '../../assets';
import { extractNameAndTag } from '../../utils/conversion'; // Import the utility function
import useDocusignApi from '../../hooks/useDocusign';

const Sales = () => {
  const tabs = ['All', 'Villas', 'Apartments', 'Land'];
  const { projects, refetch: refetchProjects } = useAllProjects();
  const {
    circle: { getWireBanks },
  } = useFantomStarterAdminApi();
  const { openModal, closeModal } = useModal();
  const { getTemplates } = useDocusignApi();

  const [activeTab, setActiveTab] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [userWireIds, setUserWireIds] = useState<string[]>([]);
  const [templateHash, setTemplateHash] = useState<{
    [templateName: string]: any;
  }>({});

  const isAccountLinked = userWireIds.length > 0;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useMemo(
    () => debounce((query: string) => setSearchQuery(query), 300),
    []
  );

  const filteredProjects = useMemo(() => {
    let filtered = projects;

    if (activeTab !== 'All') {
      filtered = projects.filter((project) => {
        const projectName = extractNameAndTag(project.name).name.toLowerCase();
        if (activeTab === 'Villas') {
          return projectName.includes('villa');
        } else if (activeTab === 'Apartments') {
          return projectName.includes('apartment');
        } else if (activeTab === 'Land') {
          return projectName.includes('land');
        }
        return true;
      });
    }

    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (project) =>
          project.name.toLowerCase().includes(lowercasedQuery) ||
          project.id.toLowerCase().includes(lowercasedQuery)
      );
    }

    return filtered;
  }, [activeTab, projects, searchQuery]);

  useEffect(() => {
    (async () => {
      const result = await getWireBanks();
      if (result.status === 200) {
        setUserWireIds(result.data ? result.data : []);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    getTemplates().then((result: any) => {
      if (!result || !result.envelopeTemplates) return;
      const templates: any[] = result.envelopeTemplates;
      const hash: { [templateName: string]: any } = {};
      templates.forEach((template) => (hash[template.name] = template));
      setTemplateHash(hash);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openAccountLinkFormModal = () => {
    openModal(<AccountLinkingForm onCancel={closeModal} />);
  };

  const SaleCardRow = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    const project = filteredProjects[index];
    return (
      <div style={style}>
        <SaleCard
          project={project}
          templateHash={templateHash}
          refetchProjects={refetchProjects}
          key={project.id}
        />
      </div>
    );
  };

  return (
    <DesktopLayout>
      <main className="pt-[92px] sm:px-32 px-5 flex flex-col sm:flex-row gap-5 sm:gap-20 items-start sm:items-center">
        <section className="flex flex-col px-32 md:px-5 w-full">
          <header className="flex gap-4 items-center w-full text-sm font-medium justify-between">
            <nav className="flex min-w-[240px] text-slate-700 w-[280px]">
              <div className="flex items-start p-1.5 w-full rounded-md bg-slate-100">
                {tabs.map((label) => (
                  <div
                    key={label}
                    className={`flex gap-2.5 px-3 py-1.5 rounded cursor-pointer ${
                      activeTab === label ? 'bg-white text-slate-900' : ''
                    }`}
                    onClick={() => setActiveTab(label)}
                  >
                    <span>{label}</span>
                  </div>
                ))}
              </div>
            </nav>
            <div className="flex flex-1 items-center min-w-[240px] max-w-[690px] rounded-md border shadow-sm border-slate-200 bg-white px-2 py-2.5 gap-2">
              <img
                loading="lazy"
                src={search}
                className="w-4"
                alt="Search icon"
              />
              <input
                type="search"
                placeholder="Search for a sale"
                className="flex-1 bg-transparent border-none outline-none"
                aria-label="Search for a sale"
                onChange={handleSearchChange}
              />
            </div>
            <div className="flex gap-4 min-w-[240px] w-[280px] justify-between">
              <div
                className={`gradient-primary ${isAccountLinked && 'opacity-50'}`}
              >
                <button
                  className={`btn-text rounded-[5px] m-[1px] pt-[7px]  px-[15px] ${
                    isAccountLinked && 'cursor-not-allowed'
                  }`}
                  onClick={openAccountLinkFormModal}
                  disabled={isAccountLinked}
                >
                  {isAccountLinked ? 'Account Linked!' : 'Link bank account'}
                </button>
              </div>
              <Link to="/sales/create">
                <button className="btn-primary">Start a sale</button>
              </Link>
            </div>
          </header>
          {filteredProjects.length > 0 ? (
            <section className="mt-6 pb-4" style={{ height: '800px' }}>
              <AutoSizer>
                {({ width, height }: { width: number; height: number }) => (
                  <List
                    height={height}
                    itemCount={filteredProjects.length}
                    itemSize={424}
                    layout="horizontal"
                    width={width}
                  >
                    {SaleCardRow}
                  </List>
                )}
              </AutoSizer>
            </section>
          ) : (
            <NoSaleFound />
          )}
        </section>
      </main>
    </DesktopLayout>
  );
};

export default Sales;
