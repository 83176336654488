import React, { useState } from 'react';
import { z } from 'zod';

import { cross } from '../../assets';

export interface BankDetails {
  accountNumber: string;
  routingNumber: string;
  bankName: string;
  city: string;
  country: string;
  line1: string;
  line2?: string;
  district: string;
}

interface BankAccountFormProps {
  onCancel: () => void;
  onSave: (bankData: BankDetails) => void;
  errors: Record<string, string>;
  isSubmitting: boolean;
  success: string;
}

export const bankDetailsSchema: z.Schema<BankDetails> = z.object({
  accountNumber: z
    .string()
    .regex(/^\d{8,12}$/, 'Account Number must be 8 to 12 digits.'),
  routingNumber: z
    .string()
    .regex(/^\d{9}$/, 'Routing Number must be 9 digits.'),
  bankName: z.string().min(1, 'Bank Name is required.'),
  city: z.string().min(1, 'City is required.'),
  country: z
    .string()
    .length(2, 'Country must be a valid ISO 3166-1 alpha-2 code.'),
  line1: z.string().min(1, 'Address Line 1 is required.'),
  line2: z.string().optional(),
  district: z.string().min(1, 'State/Province/District is required.'),
});

const BankAccountForm: React.FC<BankAccountFormProps> = ({
  onCancel,
  onSave,
  isSubmitting,
  success,
}) => {
  const [formData, setFormData] = useState<BankDetails>({
    accountNumber: '12340010',
    routingNumber: '121000248',
    bankName: 'SAN FRANCISCO',
    city: 'SAN FRANCISCO',
    country: 'US',
    line1: '100 Money Street',
    line2: 'Suite 1',
    district: 'CA',
  });
  const [localErrors, setLocalErrors] = useState<Record<string, string>>({});

  const getFieldSchema = (name: keyof BankDetails) => {
    switch (name) {
      case 'accountNumber':
        return z
          .string()
          .regex(/^\d{8,12}$/, 'Account Number must be 8 to 12 digits.');
      case 'routingNumber':
        return z.string().regex(/^\d{9}$/, 'Routing Number must be 9 digits.');
      case 'bankName':
        return z.string().min(1, 'Bank Name is required.');
      case 'city':
        return z.string().min(1, 'City is required.');
      case 'country':
        return z
          .string()
          .length(2, 'Country must be a valid ISO 3166-1 alpha-2 code.');
      case 'line1':
        return z.string().min(1, 'Address Line 1 is required.');
      case 'line2':
        return z.string().optional();
      case 'district':
        return z.string().min(1, 'State/Province/District is required.');
      default:
        return z.string();
    }
  };

  const validateField = (name: keyof BankDetails, value: string) => {
    const schema = getFieldSchema(name);
    try {
      schema.parse(value);
      setLocalErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        setLocalErrors((prevErrors) => ({
          ...prevErrors,
          [name]: err.errors[0].message,
        }));
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name as keyof BankDetails, value);
  };

  const handleSave = () => {
    try {
      bankDetailsSchema.parse(formData);
      onSave(formData);
    } catch (err) {
      if (err instanceof z.ZodError) {
        const validationErrors: Partial<Record<keyof BankDetails, string>> = {};
        err.errors.forEach((error) => {
          const fieldName = error.path[0] as keyof BankDetails;
          validationErrors[fieldName] = error.message;
        });
        setLocalErrors(validationErrors as Record<keyof BankDetails, string>);
      } else {
        console.error(err);
      }
    }
  };

  return (
    <section className="flex flex-col px-6 pt-6 pb-4 bg-white rounded-md shadow-sm border border-slate-200 max-w-[600px] z-50">
      <header className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <h1 className="text-2xl font-semibold text-slate-900">
            Add Bank Account
          </h1>
          <img
            loading="lazy"
            src={cross}
            className="w-6 h-6 cursor-pointer"
            alt="Icon"
            onClick={onCancel}
          />
        </div>
        <p className="text-base text-slate-600">
          Please enter your bank account details below. Ensure that all
          information is accurate to avoid any issues with future transactions.
        </p>
      </header>

      <form className="flex flex-col gap-4">
        {[
          {
            label: 'Account Number',
            name: 'accountNumber',
            type: 'text',
            placeholder: 'Add Account Number',
          },
          {
            label: 'Routing Number',
            name: 'routingNumber',
            type: 'text',
            placeholder: 'Add Routing Number',
          },
          {
            label: 'Bank Name',
            name: 'bankName',
            type: 'text',
            placeholder: 'Add Bank Name',
          },
          {
            label: 'City',
            name: 'city',
            type: 'text',
            placeholder: 'Add City',
          },
          {
            label: 'Country (ISO Code)',
            name: 'country',
            type: 'text',
            placeholder: 'Add Country',
          },
          {
            label: 'Address Line 1',
            name: 'line1',
            type: 'text',
            placeholder: 'Add Address',
          },
          {
            label: 'Address Line 2',
            name: 'line2',
            type: 'text',
            placeholder: 'Add Address',
          },
          {
            label: 'State/Province/District',
            name: 'district',
            type: 'text',
            placeholder: 'Add State/Province/District',
          },
        ].map(({ label, name, type, placeholder }) => (
          <div key={name} className="flex items-center">
            <label className="w-64 font-medium text-slate-700">{label}</label>
            <div className="flex-1">
              <input
                type={type}
                name={name}
                value={formData[name as keyof BankDetails]}
                onChange={handleChange}
                placeholder={placeholder}
                className="w-full py-2 px-3 bg-white border border-slate-300 rounded-md text-slate-700"
              />
              {localErrors[name] && (
                <p className="text-red-500 text-sm mt-1">{localErrors[name]}</p>
              )}
            </div>
          </div>
        ))}

        {success && <p className="text-green-500">{success}</p>}

        <hr className="w-full border-slate-200 mt-2" />

        <footer className="flex justify-end space-x-4 mt-4">
          <button type="button" onClick={onCancel} className="btn-text w-1/2">
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSave}
            disabled={isSubmitting}
            className={`btn-primary w-1/2`}
          >
            {isSubmitting ? 'Submitting...' : 'Save'}
          </button>
        </footer>
      </form>
    </section>
  );
};

export default BankAccountForm;
