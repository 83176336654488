import React, { useState, useRef, ChangeEvent } from 'react';
import { z } from 'zod';
import {
  SaleDescriptionCard,
  SaleCalendarCard,
  SaleStrategyCard,
  VisualsUploaderCard,
} from '../Cards';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

interface SaleOption {
  title: string;
  maxWinners: number;
}

const CreateSaleForm: React.FC = () => {
  const [propertyLogo, setPropertyLogo] = useState<File | null>(null);
  const [mainPropertyImage, setMainPropertyImage] = useState<File | null>(null);
  const [propertyLogoUrl, setPropertyLogoUrl] = useState<string | null>(null);
  const [mainPropertyImageUrl, setMainPropertyImageUrl] = useState<
    string | null
  >(null);
  const [saleName, setSaleName] = useState<string>('');
  const [saleTagline, setSaleTagline] = useState<string>('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [isRegisteredMembership, setIsRegisteredMembership] =
    useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const navigate = useNavigate();
  const fantomStarterAdminApi = useFantomStarterAdminApi();
  const { createProject } = fantomStarterAdminApi.admin;

  const saleOptions: SaleOption[] = [
    { title: 'Open', maxWinners: 25 },
    { title: 'Private', maxWinners: 50 },
    { title: 'Auction', maxWinners: 100 },
  ];

  const schema = z.object({
    saleName: z.string().min(1, 'Enter sale name'),
    saleTagline: z.string().min(1, 'Enter sale tagline'),
    selectedOption: z.string().nonempty('Select a sale strategy'),
    propertyLogoUrl: z
      .string()
      .min(1, 'Enter property logo')
      .nonempty('Enter property logo'),
    mainPropertyImageUrl: z
      .string()
      .min(1, 'Enter main property image')
      .nonempty('Enter main property image'),
    startDate: z
      .string()
      .min(1, 'Enter start date')
      .refine((value) => value !== null, {
        message: 'Start date is required',
      }),
    endDate: z
      .string()
      .min(1, 'Enter end date')
      .refine((value) => value !== null, {
        message: 'End date is required',
      }),
  });

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  const uploadImageToCloudinary = async (file: File) => {
    const uploadPreset =
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET ?? 'default_upload_preset';
    const cloudName =
      process.env.REACT_APP_CLOUDINARY_CLOUD_NAME ?? 'default_cloud_name';

    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', uploadPreset);
    data.append('cloud_name', cloudName);

    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
      {
        method: 'POST',
        body: data,
      }
    );
    const result = await response.json();
    return result.secure_url;
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsUploading(true);

    try {
      let uploadedPropertyLogoUrl = propertyLogoUrl;
      let uploadedMainPropertyImageUrl = mainPropertyImageUrl;

      if (propertyLogo) {
        uploadedPropertyLogoUrl = await uploadImageToCloudinary(propertyLogo);
        setPropertyLogoUrl(uploadedPropertyLogoUrl);
      }
      if (mainPropertyImage) {
        uploadedMainPropertyImageUrl =
          await uploadImageToCloudinary(mainPropertyImage);
        setMainPropertyImageUrl(uploadedMainPropertyImageUrl);
      }

      const result = schema.safeParse({
        saleName,
        saleTagline,
        selectedOption: selectedOption || '',
        propertyLogoUrl: uploadedPropertyLogoUrl || '',
        mainPropertyImageUrl: uploadedMainPropertyImageUrl || '',
        startDate: startDate || '',
        endDate: endDate || '',
      });

      if (result.success) {
        setErrors({});

        const apiResult = await createProject({
          name: `${saleName} --rwa`,
          description: saleTagline,
          tags: [],
          chain: '80002', // todo: mainnet testnet chain id depending on the deployment
          logo_image_url: uploadedPropertyLogoUrl || '',
          main_image_url: uploadedMainPropertyImageUrl || '',
          start_date_whitelist: null,
          end_date_whitelist: null,
          start_date_funding: startDate,
          end_date_funding: endDate,
          ido_token_symbol: 'NFT',
          pay_token_symbol: 'USDC',
          ratio_idotoken_per_paytoken: 0.0001,
          ratio_paytoken_per_idotoken: 10000,
          sale_version: 3,
          with_whitelist: selectedOption === 'Private',
          with_lottery: selectedOption === 'Auction',
          only_staked: false,
          with_kyc: false,
          header_image_url: uploadedMainPropertyImageUrl,
          company: null,
          location: null,
        });

        if (apiResult.status === 200) {
          toast.success('Project Successfully Created!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          navigate('/sales');
        } else {
          toast.error('Failed to create Project', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        const validationErrors: Record<string, string> = {};
        result.error.errors.forEach((err) => {
          if (err.path[0]) {
            validationErrors[err.path[0]] = err.message;
          }
        });
        setErrors(validationErrors);
      }
    } catch (error) {
      console.error('Error uploading images or creating project:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setState(event.target.value);
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    setState(event.target.value);
  };

  const handleCancel = () => {
    console.log('Cancel clicked');
  };

  return (
    <form
      onSubmit={handleSave}
      className="max-w-lg mx-auto mt-20 bg-[#F8FAFC] p-6 rounded-md"
    >
      <div className="flex flex-col items-center">
        <h2 className="text-3xl font-semibold">Launch Your Deal</h2>
        <p className="m-0 font-medium">
          Fill all fields to create your sale in under 60 seconds
        </p>
      </div>

      <SaleDescriptionCard
        saleName={saleName}
        saleTagline={saleTagline}
        errors={errors}
        handleInputChange={handleInputChange}
        setSaleName={setSaleName}
        setSaleTagline={setSaleTagline}
      />

      <SaleStrategyCard
        saleOptions={saleOptions}
        selectedOption={selectedOption}
        isRegisteredMembership={isRegisteredMembership}
        errors={errors}
        handleOptionChange={setSelectedOption}
        handleToggle={() => setIsRegisteredMembership(!isRegisteredMembership)}
      />

      <VisualsUploaderCard
        propertyLogo={propertyLogo}
        mainPropertyImage={mainPropertyImage}
        errors={errors}
        handleDrop={handleDrop}
        handleFileChange={handleFileChange}
        setPropertyLogo={setPropertyLogo}
        setMainPropertyImage={setMainPropertyImage}
      />

      <SaleCalendarCard
        startDate={startDate}
        endDate={endDate}
        errors={errors}
        handleDateChange={handleDateChange}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDateInputRef={useRef(null)}
        endDateInputRef={useRef(null)}
      />

      <section className="flex mt-6 w-full gap-4">
        <button
          type="button"
          onClick={handleCancel}
          className="btn-text bg-[#F8FAFC] flex-1 text-base font-medium py-4"
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`btn-primary flex-1 text-base font-medium py-4 ${
            isUploading ? 'opacity-50' : ''
          }`}
          disabled={isUploading}
        >
          {isUploading ? 'Saving...' : 'Save'}
        </button>
      </section>
      <ToastContainer />
    </form>
  );
};

export default CreateSaleForm;
