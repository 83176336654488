import { FC, useState } from 'react';

interface DeleteConfirmationProps {
  onCancel: () => void;
  onDelete: () => Promise<void>;
}

const DeleteConfirmation: FC<DeleteConfirmationProps> = ({
  onCancel,
  onDelete,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <section className="p-6 max-w-lg bg-white rounded-md border border-slate-300 z-50">
      <header>
        <h2 className="text-lg font-semibold text-slate-900">Delete</h2>
        <p className="mt-2 text-sm font-normal text-slate-500">
          This action cannot be undone. All associated data will be permanently
          lost. Please confirm to proceed.
        </p>
      </header>
      <footer className="flex justify-end gap-2 mt-4">
        <button className="btn-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button
          className={`btn-danger ${isDeleting ? 'opacity-50 cursor-wait' : ''}`}
          onClick={async () => {
            setIsDeleting(true);
            await onDelete();
            setIsDeleting(false);
            onCancel();
          }}
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </button>
      </footer>
    </section>
  );
};

export default DeleteConfirmation;
