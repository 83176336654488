import { FC } from 'react';
import { toast } from 'react-toastify';

import useModal from '../../hooks/useModal';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import { DeleteConfirmationModal } from '../Modal';

interface ISaleCardMenuProps {
  project: any;
  refetchProjects: () => void;
}

const SaleCardMenu: FC<ISaleCardMenuProps> = ({ project, refetchProjects }) => {
  const { openModal, closeModal } = useModal();
  const {
    admin: { deleteProject },
  } = useFantomStarterAdminApi();

  const openDeleteConfirmationDialog = () => {
    openModal(
      <DeleteConfirmationModal
        onCancel={() => {
          closeModal();
        }}
        onDelete={async () => {
          const result = await deleteProject(project.id);
          refetchProjects();
          if (result.status === 200) {
            toast.success('Project Successfully Deleted!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } else {
            toast.error('Failed to delete Project', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        }}
      />
    );
  };

  const items = [
    {
      text: 'See summary',
      color: 'slate-700',
      onClick: () => console.log('See summary'),
    },
    {
      text: 'Delete',
      color: 'red-500',
      onClick: () => openDeleteConfirmationDialog(),
    },
  ];

  return (
    <nav
      aria-label="Main Menu"
      className="max-w-xs min-w-[216px] rounded-md shadow-sm border border-slate-100"
    >
      <ul className="flex flex-col p-2 bg-white">
        {items.map((item, index) => (
          <li
            key={index}
            className={
              index === items.length - 1 ? 'border-t border-slate-200' : ''
            }
          >
            <button
              className={`w-full text-left py-2 px-3 text-${item.color} hover:bg-slate-50 focus:outline-none`}
              onClick={() => item.onClick()}
            >
              {item.text}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SaleCardMenu;
