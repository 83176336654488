import { FC, useState } from 'react';
import { cross } from '../../assets';

interface IOtpInputProps {
  headerTitle: string;
  email: string;
  onClose: () => void;
  onVerify: (
    otpValue: string,
    setIsVerifyingOtp: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
}

const OtpInput: FC<IOtpInputProps> = ({
  headerTitle,
  email,
  onClose,
  onVerify,
}) => {
  const length = 6;
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
  const [isVerifyingOtp, setIsVerifyingOtp] = useState<boolean>(false);

  const handleChange = (value: string, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value.length === 1 && index < length - 1) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const otpValue = otp.join('');
    if (otpValue.length === length) {
      onVerify(otpValue, setIsVerifyingOtp);
    } else {
      alert('Please fill all the fields.');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-96 relative">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-medium text-center">{headerTitle}</h2>
        <button onClick={onClose} className="text-white focus:outline-none">
          <img src={cross} alt="Close" />
        </button>
      </div>

      <p className="text-start mb-4 text-slate-500 text-sm">
        Enter the verification code sent to
      </p>
      <p className="text-center mb-6 text-slate-600 font-medium">{email}</p>

      <form onSubmit={handleSubmit}>
        <div className="flex justify-center mb-4 space-x-2">
          {otp.map((_, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              value={otp[index]}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              maxLength={1}
              className="w-10 h-10 text-center text-lg border-2 border-slate-400 bg-transparent text-black rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          ))}
        </div>
        <button type="submit" className="btn-primary w-full">
          {isVerifyingOtp ? 'Verifying...' : 'Verify'}
        </button>
      </form>

      <div className="mt-4 text-center">
        <button
          className="btn-text w-full hover:underline text-sm"
          disabled={isVerifyingOtp}
        >
          Resend verification code
        </button>
      </div>
    </div>
  );
};

export default OtpInput;
