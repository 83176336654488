import React from 'react';
import { Link } from 'react-router-dom';
import Plus from '../../assets/img/plus.svg';

const EmptyCard: React.FC = () => {
  return (
    <div className="MainFrame  w-[90%] lg:w-full h-[491.40px] p-5 bg-zinc-900 rounded-[30px] flex justify-center items-center">
      <div className="MainContent w-[345px] h-[400.40px] relative rounded-bl-[20px] rounded-br-[20px] backdrop-blur-[100px] flex flex-col justify-start items-start">
        <div className="HeadingWithLogoAndLinks w-[269px] h-[66px] justify-start items-center gap-5 inline-flex">
          <div className="Rectangle6 w-[66px] h-[66px] bg-zinc-700 rounded-[10px]" />
          <div className="Frame38 flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="Card w-[183px] h-[25px] py-[5px] bg-zinc-700 rounded-[60px]  justify-center items-center gap-2 inline-flex" />
            <div className="Links self-stretch justify-start items-center gap-2.5 inline-flex">
              <div className="Card w-[57px] h-[21px] py-[5px] bg-zinc-700 rounded-[60px]  justify-center items-center gap-2 flex" />
              <div className="Card w-[47px] h-[21px] py-[5px] bg-zinc-700 rounded-[60px]  justify-center items-center gap-2 flex" />
            </div>
          </div>
        </div>
        <div className="Frame5701 w-[187px] h-3.5 justify-start items-center gap-[15px] mt-5 inline-flex">
          <div className="Frame5699 justify-start items-center gap-1.5 flex">
            <div className="Info bg-zinc-700 rounded-[10px] flex-col justify-start items-start gap-0.5 inline-flex">
              <div className="Info w-[77px] h-3.5 bg-zinc-700 rounded-[10px] flex-col justify-start items-start gap-0.5 inline-flex" />
            </div>
          </div>
          <div className="Frame5670 pt-px justify-start items-center gap-2.5 flex">
            <div className="Ellipse1 w-[3px] h-[3px] bg-neutral-700 rounded-full" />
          </div>
          <div className="Frame5700 justify-start items-start gap-1.5 flex">
            <div className="Info bg-zinc-700 rounded-[10px] flex-col justify-start items-start gap-0.5 inline-flex">
              <div className="Info w-[77px] h-3.5 bg-zinc-700 rounded-[10px] flex-col justify-start items-start gap-0.5 inline-flex" />
            </div>
          </div>
        </div>
        <div className="Frame5682 justify-start items-start gap-[50px] inline-flex mt-5">
          <div className="Frame5669 flex-col justify-start items-start gap-[15px] inline-flex">
            <div className="Frame5703 justify-start items-center gap-2.5 inline-flex">
              <div className="Info w-[169px] h-[25px] bg-zinc-700 rounded-[20px] flex-col justify-start items-start inline-flex" />
            </div>
            <div className="Frame5704 justify-start items-center gap-2.5 inline-flex">
              <div className="Info w-[238px] h-[25px] bg-zinc-700 rounded-[20px] flex-col justify-start items-start inline-flex" />
            </div>
          </div>
        </div>
        <Link
          to="/sales/create"
          className="Button1 w-[205px] h-[59px] px-10 py-5 bg-purple-500 rounded-[40px] flex justify-center items-center absolute top-[160px] left-1/2 transform -translate-x-1/2"
        >
          <span className="flex items-center gap-2">
            <span className="Plus w-[19px] h-[19px] relative inline">
              <img src={Plus} className="w-[19px] h-[19px]" alt={Plus} />
            </span>
            <span className="Label text-center text-black text-xl font-semibold leading-[18px] whitespace-nowrap">
              Start a sale
            </span>
          </span>
        </Link>

        <div className="Frame5698 w-[270px] h-12 flex-col justify-start items-start gap-5 inline-flex mt-8">
          <div className="Frame5696 flex-col justify-start items-start gap-1.5 flex">
            <div className="Frame5695 justify-start items-center gap-[30px] inline-flex">
              <div className="Frame5670 bg-zinc-700 rounded-[10px] justify-start items-center gap-1.5 flex">
                <div className="Frame5670 w-[120px] h-3.5 bg-zinc-700 rounded-[10px] justify-start items-center gap-1.5 inline-flex" />
              </div>
              <div className="Frame5671 bg-zinc-700 rounded-[10px] justify-start items-center gap-1.5 flex">
                <div className="Frame5670 w-[120px] h-3.5 bg-zinc-700 rounded-[10px] justify-start items-center gap-1.5 inline-flex" />
              </div>
            </div>
          </div>
          <div className="Frame5698 flex-col justify-start items-start gap-1.5 flex">
            <div className="Frame5695 justify-start items-center gap-[30px] inline-flex">
              <div className="Frame5670 bg-zinc-700 rounded-[10px] justify-start items-center gap-1.5 flex">
                <div className="Frame5670 w-[120px] h-3.5 bg-zinc-700 rounded-[10px] justify-start items-center gap-1.5 inline-flex" />
              </div>
              <div className="Frame5671 bg-zinc-700 rounded-[10px] justify-start items-center gap-1.5 flex">
                <div className="Frame5670 w-[120px] h-3.5 bg-zinc-700 rounded-[10px] justify-start items-center gap-1.5 inline-flex" />
              </div>
            </div>
          </div>
        </div>
        <div className="Frame5664 h-[30.40px] flex-col justify-start items-start gap-[5px] inline-flex mt-5">
          <div className="Frame22 w-[305px] h-[15px] justify-between items-end gap-[5px] inline-flex">
            <div className="Frame22 w-[88px] h-[15px] bg-zinc-700 rounded-[10px] justify-start items-center gap-[5px] flex" />
            <div className="Frame5716 w-[29px] h-[15px] relative bg-zinc-700 rounded-[10px]" />
          </div>
          <div className="Group21 self-stretch h-[10.40px] relative">
            <div className="Rectangle9 w-[305px] h-2.5 left-0 top-[0.40px] absolute bg-zinc-700 rounded-[10px]" />
            <div className="Rectangle10 w-[111.14px] h-2.5 left-0 top-0 absolute bg-zinc-700 rounded-[10px]" />
          </div>
        </div>
        <div className="Frame5702  flex  gap-3 w-full flex-wrap justify-center items-center mt-5">
          <div className="Button5 w-[94px] h-[38px] px-[18px] py-2.5 bg-zinc-700 rounded-[60px]  justify-start items-center gap-1.5 inline-flex">
            <div className="VuesaxBoldTruckTick w-4 h-4 justify-center items-center flex">
              <div className="TruckTick w-4 h-4 relative"></div>
            </div>
            <div className="Label text-center text-purple-500 text-opacity-0 text-sm font-semibold leading-[18px]">
              Deploy{' '}
            </div>
          </div>
          <div className="Button5 w-[94px] h-[38px] px-[18px] py-2.5 bg-zinc-700 rounded-[60px]  justify-start items-center gap-1.5 inline-flex">
            <div className="VuesaxBoldTruckTick w-4 h-4 justify-center items-center flex">
              <div className="TruckTick w-4 h-4 relative"></div>
            </div>
            <div className="Label text-center text-purple-500 text-opacity-0 text-sm font-semibold leading-[18px]">
              Deploy{' '}
            </div>
          </div>
          <div className="Button5 w-[94px] h-[38px] px-[18px] py-2.5 bg-zinc-700 rounded-[60px]  justify-start items-center gap-1.5 inline-flex">
            <div className="VuesaxBoldTruckTick w-4 h-4 justify-center items-center flex">
              <div className="TruckTick w-4 h-4 relative"></div>
            </div>
            <div className="Label text-center text-purple-500 text-opacity-0 text-sm font-semibold leading-[18px]">
              Deploy{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCard;
