import React from 'react';

import { Login } from '../views/Auth';
import useToken from '../hooks/useToken';

function withValidLogin(WrappedComponent: React.FC) {
  return function WithValidLoginComponent({ ...props }) {
    const { token } = useToken();

    if (!token) {
      return (
        <>
          <Login />
        </>
      );
    }

    return <WrappedComponent {...props} />;
  };
}

export default withValidLogin;
