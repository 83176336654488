import React from 'react';

interface SaleOption {
  title: string;
  maxWinners: number;
}

const SaleStrategyCard: React.FC<{
  saleOptions: SaleOption[];
  selectedOption: string | null;
  isRegisteredMembership: boolean;
  errors: Record<string, string>;
  handleOptionChange: (title: string) => void;
  handleToggle: () => void;
}> = ({
  saleOptions,
  selectedOption,
  isRegisteredMembership,
  errors,
  handleOptionChange,
  handleToggle,
}) => (
  <section className="flex flex-col px-6 py-4 mt-6 w-full bg-white rounded-md border border-[#CBD5E1]">
    <header className="w-full text-sm font-semibold text-black">
      <h2 className="text-lg">Sale Strategy</h2>
      <hr className="mt-4 w-full border-slate-200" aria-hidden="true" />
    </header>
    <div className="flex flex-col mt-8 w-full">
      <div className="flex gap-4 items-center w-full">
        {saleOptions.map((option, index) => (
          <div
            key={index}
            className={`flex flex-col flex-1 p-3 bg-white rounded-md border min-h-[128px] ${
              errors.selectedOption ? 'border-red-500' : 'border-[#CBD5E1]'
            }`}
          >
            <label className="flex flex-col flex-1 justify-between cursor-pointer">
              <input
                type="radio"
                name="saleOption"
                checked={selectedOption === option.title}
                onChange={() => handleOptionChange(option.title)}
                className="sr-only"
                aria-label={`Select sale strategy: ${option.title}`}
              />
              <div className="flex items-start justify-start mb-2">
                <div
                  className={`w-4 h-4 rounded-full border ${
                    selectedOption === option.title
                      ? 'bg-[#334155] border-[#334155]'
                      : 'border-gray-300'
                  }`}
                ></div>
              </div>
              <h3 className="self-center text-base font-medium text-black">
                {option.title}
              </h3>
              <p className="mt-10 text-xs font-light text-center text-[#334155]">
                Max buyers: {option.maxWinners}
              </p>
            </label>
          </div>
        ))}
      </div>
      {errors.selectedOption && (
        <p className="text-red-500 text-xs mt-1">{errors.selectedOption}</p>
      )}
    </div>
    <div className="flex gap-1.5 items-start mt-4 w-full">
      <label
        htmlFor="registeredMembership"
        className="flex-1 text-sm font-medium text-black"
      >
        Members only?
      </label>
      <div className="flex gap-2 items-center">
        <button
          id="registeredMembership"
          onClick={handleToggle}
          className={`flex justify-center items-start p-0.5 w-11 h-6 rounded-full transition-colors duration-200 ${
            isRegisteredMembership ? 'bg-[#334155]' : 'bg-slate-200'
          }`}
          aria-checked={isRegisteredMembership}
          role="switch"
          type="button"
        >
          <span
            className={`w-5 h-5 bg-white rounded-full shadow-sm transform transition-transform duration-200 ${
              isRegisteredMembership ? 'translate-x-2.5' : '-translate-x-2.5'
            }`}
          />
        </button>
        <span className="text-sm font-medium text-black w-6">
          {isRegisteredMembership ? 'Yes' : 'No'}
        </span>
      </div>
    </div>
  </section>
);

export default SaleStrategyCard;
