import { Link } from 'react-router-dom';

import { heartCrack } from '../../assets';

const NoSaleFound = () => {
  return (
    <section className="flex flex-col items-start py-20 mt-6 w-full">
      <div className="flex flex-col text-slate-900">
        <img
          loading="lazy"
          src={heartCrack}
          className="w-20"
          alt="No sales icon"
        />
        <div className="flex flex-col mt-2">
          <h2 className="text-3xl font-semibold">No sales found</h2>
          <p className="mt-2 text-xl">Looks like you don't have sales yet</p>
        </div>
      </div>
      <Link to="/sales/create">
        <button className="btn-primary mt-4">Start a sale</button>
      </Link>
    </section>
  );
};

export default NoSaleFound;
