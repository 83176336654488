import React, { useState } from 'react';

interface Step1Props {
  setResponse: (response: any) => void;
  onNextClick: () => void;
  response?: any;
}

const Step1: React.FC<Step1Props> = ({
  setResponse,
  onNextClick,
  response,
}) => {
  const [inputVal, setInputVal] = useState<any>({
    name: response.name ? response.name : '',
    description: response.description ? response.description : '',
    tags: '',
    chain: response.chain ? response.chain : 0,
    logo_image_url: response.logo_image_url ? response.logo_image_url : '',
    main_image_url: response.main_image_url ? response.main_image_url : '',
    start_date_whitelist: response.start_date_whitelist
      ? response.start_date_whitelist
      : null,
    end_date_whitelist: response.end_date_whitelist
      ? response.end_date_whitelist
      : null,
    start_date_funding: response.start_date_funding
      ? response.start_date_funding
      : null,
    end_date_funding: response.end_date_funding
      ? response.end_date_funding
      : null,
    ido_token_symbol: response.ido_token_symbol
      ? response.ido_token_symbol
      : '',
    pay_token_symbol: response.pay_token_symbol
      ? response.pay_token_symbol
      : 'axlUDSC',
    ratio: null,
    sale_version: 0,
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal((prevInputVal: any) => ({
      ...prevInputVal,
      name: e.target.value,
    }));
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setInputVal((prevInputVal: any) => ({
      ...prevInputVal,
      description: e.target.value,
    }));
  };

  const handleNextClick = () => {
    setResponse(inputVal);
    onNextClick();
  };

  const handleSubmit = () => {
    setResponse(inputVal);
  };

  return (
    <>
      <div className="overflow-x-hidden overflow-y-hidden sm:overflow-visible w-full sm:w-fit px-5 sm:px-0">
        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.03)',
            borderColor: 'rgba(255, 255, 255, 0.03)',
          }}
          className="mt-[50px] h-[537px]  p-[20px] rounded-[30px] border-[2px]  sm:mt-[40px] w-full sm:w-[490px] sm:h-[570px]   sm:p-[45px] sm:rounded-[30px] sm:border-[2px] flex-col justify-start items-start gap-[30px] inline-flex"
        >
          <div className=" justify-start items-start inline-flex ">
            <p className="text-[30px] leading-[64px]  font-[700]   text-transparent bg-clip-text bg-gradient-to-r from-[#987EF5] to-[#F19571] opacity-100 sm:text-[40px] sm:leading-[64px]  sm:font-[700]  sm:text-transparent sm:bg-clip-text sm:bg-gradient-to-r sm:from-[#987EF5] sm:to-[#F19571] sm:opacity-100">
              First, the basics
            </p>
          </div>

          <div className=" flex-col justify-start items-start gap-2.5 flex w-full">
            <p className="text-[20px] text-[#A68DFF] font-[600]  sm:text-[20px] sm:text-[#A68DFF] sm:font-[600]">
              Project name
            </p>

            <input
              className="  rounded-[15px] p-[20px] w-full text-white bg-[#000000] opacity-30 border-[1px] border-opacity-60  inset-0 z-10 placeholder:text-white   sm:rounded-[15px] sm:p-[20px] sm:w-[400px] sm:text-white sm:bg-[#000000] sm:opacity-30 sm:border-[1px] sm:border-opacity-60  sm:inset-0 sm:z-10 sm:placeholder:text-white"
              type="text"
              placeholder="eg. AAVE"
              value={inputVal.name}
              onChange={handleNameChange}
            />
          </div>

          <div className="flex-col justify-start items-start gap-2.5  flex w-full">
            <div className="DescribeYourProjectMax300Characters">
              <span className="text-violet-400 text-xl font-semibold">
                Project tagline
              </span>
            </div>

            <textarea
              className="rounded-[18px] p-[20px] h-[134px] w-full text-white bg-[#000000] opacity-30 border-[1px] border-opacity-60  inset-0 z-10 placeholder:text-white overflow-y-auto overflow-x-contain size-none text-[top]   sm:rounded-[18px] sm:p-[20px] sm:h-[134px] sm:w-[400px] sm:text-white sm:bg-[#000000] sm:opacity-30 sm:border-[1px] sm:border-opacity-60  sm:inset-0 sm:z-10 sm:placeholder:text-white sm:overflow-y-auto sm:overflow-x-contain sm:size-none sm:text-[top]"
              placeholder="eg. Earn interest, borrow assets, and build applications"
              value={inputVal.description}
              onChange={handleDescriptionChange}
            />
          </div>

          <div className="w-full flex flex-row justify-between gap-5">
            <div className="Card w-[103px] h-[45px]  px-6 py-2 rounded-[60px] border border-zinc-500 justify-center items-center gap-2 inline-flex flex-shrink-0">
              <button
                disabled
                className="Back  text-zinc-500 text-base font-semibold flex-shrink-0"
              >
                ← Back
              </button>
            </div>
            <button
              className={`Button relative sm:left-0 w-[200px] sm:w-[280px] h-[45px] px-8 py-[13px]  bg-gradient-to-r from-[#C25FFF] to-[#3D29D0]  drop-shadow-[8_-2px_4px_rgba(0, 0, 0, 0.25) z-10 ${
                !inputVal.description || !inputVal.name
                  ? 'opacity-50'
                  : 'opacity-100'
              } rounded-[20px] shadow justify-center items-center gap-2 flex`}
              disabled={!inputVal.description || !inputVal.name}
              onClick={() => {
                handleNextClick();
                handleSubmit();
              }}
            >
              <div className="Next text-white text-base font-semibold">
                Next
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
