import React from 'react';

interface TooltipMessageProps {
  message: string;
}

const TooltipMessage: React.FC<TooltipMessageProps> = ({ message }) => {
  return (
    <div
      className="flex items-center gap-2.5 px-3 py-2 text-sm font-medium text-black bg-white rounded shadow-custom-tooltip"
      role="tooltip"
      aria-label={message}
    >
      <span>{message}</span>
    </div>
  );
};

export default TooltipMessage;
