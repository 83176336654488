import React from 'react';

import { unchecked, arrowDown, clock } from '../../assets';

interface TableData {
  date: string;
  id: string;
  email: string;
  timestamp: string;
  status: string;
}

const tableData: TableData[] = [
  {
    date: '3/26/2024',
    id: '29c31076-f5cf-4b65-9001-0ab262c6260b',
    email: 'john.doe@gmail.com',
    timestamp: '27/05/2024 5:33 PM',
    status: 'Sent',
  },
  {
    date: '3/26/2024',
    id: '29c31076-f5cf-4b65-9001-0ab262c6260b',
    email: 'john.doe@gmail.com',
    timestamp: '27/05/2024 5:33 PM',
    status: 'Sent',
  },
  {
    date: '3/26/2024',
    id: '29c31076-f5cf-4b65-9001-0ab262c6260b',
    email: 'john.doe@gmail.com',
    timestamp: '27/05/2024 5:33 PM',
    status: 'Sent',
  },
];

interface ITableProps {
  columnWidth: number;
}

const Table: React.FC<ITableProps> = ({ columnWidth }) => (
  <section className="w-full">
    {tableData.map((row, index) => (
      <section
        className="grid grid-cols-[56px_repeat(6,1fr)] gap-0 bg-white w-full text-sm whitespace-nowrap border-b-[1px]"
        key={index}
      >
        <div className="h-full flex items-center">
          <img
            loading="lazy"
            src={unchecked}
            alt="check-box"
            className="w-4 aspect-square mx-auto"
          />
        </div>

        <div
          className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
        >
          {row.date}
        </div>
        <div
          className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
        >
          {row.id}
        </div>
        <div
          className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
        >
          {row.email}
        </div>
        <div
          className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
        >
          {row.timestamp}
        </div>

        <div className="flex gap-2 items-center px-4 py-4">
          <img loading="lazy" src={clock} alt={row.status} className="w-4" />
          <span>{row.status}</span>
        </div>

        <div className="flex justify-end items-center px-4 py-4">
          <div className="flex gap-2 items-center border border-slate-300 rounded-md px-4 py-2 w-[124px]">
            <span className="flex-1">Select</span>
            <img loading="lazy" src={arrowDown} alt="" className="w-4" />
          </div>
        </div>
      </section>
    ))}
  </section>
);

export default Table;
