import React, { useState } from 'react';
import { z } from 'zod';

import { cross } from '../../assets';

export interface BillingDetails {
  name: string;
  city: string;
  country: string;
  line1: string;
  line2?: string;
  district: string;
  postalCode: string;
}

interface BillingAddressFormProps {
  onCancel: () => void;
  onSave: (billingData: BillingDetails) => void;
}

export const billingDetailsSchema: z.Schema<BillingDetails> = z.object({
  name: z.string().min(1, 'Name is required.'),
  city: z.string().min(1, 'City is required.'),
  country: z
    .string()
    .regex(/^[A-Z]{2}$/, 'Country must be a valid ISO 3166-1 alpha-2 code.'),
  line1: z.string().min(1, 'Address Line 1 is required.'),
  line2: z.string().optional(),
  district: z.string().min(1, 'State/Province/District is required.'),
  postalCode: z.string().min(1, 'Postal Code is required.'),
});

const BillingAddressForm: React.FC<BillingAddressFormProps> = ({
  onCancel,
  onSave,
}) => {
  const [formData, setFormData] = useState<BillingDetails>({
    name: 'Satoshi Nakamoto',
    city: 'Boston',
    country: 'US',
    line1: '100 Money Street',
    line2: 'Suite 1',
    district: 'MA',
    postalCode: '01234',
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const getFieldSchema = (name: keyof BillingDetails) => {
    switch (name) {
      case 'name':
        return z.string().min(1, 'Name is required.');
      case 'city':
        return z.string().min(1, 'City is required.');
      case 'country':
        return z
          .string()
          .regex(
            /^[A-Z]{2}$/,
            'Country must be a valid ISO 3166-1 alpha-2 code.'
          );
      case 'line1':
        return z.string().min(1, 'Address Line 1 is required.');
      case 'line2':
        return z.string().optional();
      case 'district':
        return z.string().min(1, 'State/Province/District is required.');
      case 'postalCode':
        return z.string().min(1, 'Postal Code is required.');
      default:
        return z.string();
    }
  };

  const validateField = (name: keyof BillingDetails, value: string) => {
    const schema = getFieldSchema(name);
    try {
      schema.parse(value);
      setErrors((prev) => ({ ...prev, [name]: '' }));
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        setErrors((prev) => ({
          ...prev,
          [name]: err.errors[0].message,
        }));
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name as keyof BillingDetails, value);
  };

  const handleSave = () => {
    try {
      billingDetailsSchema.parse(formData);
      onSave(formData);
    } catch (err) {
      if (err instanceof z.ZodError) {
        const validationErrors: Partial<Record<keyof BillingDetails, string>> =
          {};
        err.errors.forEach((error) => {
          const fieldName = error.path[0] as keyof BillingDetails;
          validationErrors[fieldName] = error.message;
        });
        setErrors(validationErrors as Record<keyof BillingDetails, string>);
      } else {
        console.error(err);
      }
    }
  };

  return (
    <section className="flex flex-col px-6 pt-6 pb-4 bg-white rounded-md shadow-sm border border-slate-200 max-w-[600px] z-50">
      <header className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <h1 className="text-2xl font-semibold text-slate-900">
            Add Billing Address
          </h1>
          <img
            loading="lazy"
            src={cross}
            className="w-6 h-6 cursor-pointer"
            alt="Icon"
            onClick={onCancel}
          />
        </div>
        <p className="text-base text-slate-600">
          Please enter the details of the new billing address you'd like to add.
          This address will be used for all future billing purposes.
        </p>
      </header>

      <form className="flex flex-col gap-4">
        {[
          {
            label: 'Name',
            name: 'name',
            type: 'text',
            placeholder: 'Add a Name for your Address',
          },
          {
            label: 'City',
            name: 'city',
            type: 'text',
            placeholder: 'Add City',
          },
          {
            label: 'Country (ISO Code)',
            name: 'country',
            type: 'text',
            placeholder: 'Add Country',
          },
          {
            label: 'Address Line 1',
            name: 'line1',
            type: 'text',
            placeholder: 'Add Address',
          },
          {
            label: 'Address Line 2',
            name: 'line2',
            type: 'text',
            placeholder: 'Add Address',
          },
          {
            label: 'State/Province/District',
            name: 'district',
            type: 'text',
            placeholder: 'Add State/Province/District',
          },
          {
            label: 'Postal Code',
            name: 'postalCode',
            type: 'text',
            placeholder: 'Add Postal Code',
          },
        ].map(({ label, name, type, placeholder }) => (
          <div key={name} className="flex items-center">
            <label className="w-64 font-medium text-slate-700">{label}</label>
            <div className="flex-1">
              <input
                type={type}
                name={name}
                value={formData[name as keyof BillingDetails]}
                onChange={handleChange}
                placeholder={placeholder}
                className="w-full py-2 px-3 bg-white border border-slate-300 rounded-md text-slate-700"
              />
              {errors[name] && (
                <p className="text-red-500 text-sm mt-1">{errors[name]}</p>
              )}
            </div>
          </div>
        ))}

        <hr className="w-full border-slate-200 mt-2" />

        <footer className="flex justify-end space-x-4">
          <button type="button" className="btn-text w-1/2" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="button"
            className="btn-primary w-1/2"
            onClick={handleSave}
          >
            Save
          </button>
        </footer>
      </form>
    </section>
  );
};

export default BillingAddressForm;
