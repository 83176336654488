import React from 'react';

interface DocumentActionsProps {
  actions: Array<{
    label: string;
    onClick: () => void;
    isDestructive?: boolean;
  }>;
}

const DocumentActions: React.FC<DocumentActionsProps> = ({ actions }) => {
  return (
    <nav className="absolute right-0 bottom-12 flex flex-col p-1.5 bg-white max-w-[224px] text-slate-700 text-sm font-medium border rounded-md shadow-lg">
      {actions.map((action, index) => {
        const buttonClasses = `
          flex items-start text-start px-2 py-1.5 w-full
          ${action.isDestructive ? 'text-red-500' : 'text-slate-700'}
          ${index === actions.length - 1 ? 'border-t border-slate-200' : ''}
        `;

        return (
          <button
            key={index}
            className={buttonClasses.trim()}
            onClick={action.onClick}
            aria-label={action.label}
          >
            <span className="flex-1 truncate">{action.label}</span>
          </button>
        );
      })}
    </nav>
  );
};

export default DocumentActions;
