import React from 'react';

const SaleDescriptionCard: React.FC<{
  saleName: string;
  saleTagline: string;
  errors: Record<string, string>;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => void;
  setSaleName: React.Dispatch<React.SetStateAction<string>>;
  setSaleTagline: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  saleName,
  saleTagline,
  errors,
  handleInputChange,
  setSaleName,
  setSaleTagline,
}) => (
  <section className="flex flex-col px-6 py-4 w-full text-sm bg-white rounded-md border border-[#CBD5E1] mt-8">
    <header className="w-full font-semibold text-black">
      <h1 className="text-lg">Sale Description</h1>
      <hr className="mt-4 w-full border-slate-200" aria-hidden="true" />
    </header>
    <div className="flex flex-col w-full mt-8">
      <label htmlFor="saleName" className="font-medium text-slate-900">
        Sale Name
      </label>
      <input
        type="text"
        id="saleName"
        name="saleName"
        placeholder="e.g., Bali Jungle Villa"
        value={saleName}
        onChange={(e) => handleInputChange(e, setSaleName)}
        className={`mt-1.5 py-2 pr-14 pl-3 w-full bg-white rounded-md border ${
          errors.saleName ? 'border-red-500' : 'border-[#CBD5E1]'
        }`}
      />
      {errors.saleName && (
        <p className="text-red-500 text-xs mt-1">{errors.saleName}</p>
      )}
    </div>
    <div className="flex flex-col mt-4 w-full">
      <label htmlFor="saleTagline" className="font-medium text-black">
        Sale Tagline
      </label>
      <textarea
        id="saleTagline"
        name="saleTagline"
        placeholder="e.g., Escape to Serenity: Hilltop Haven in Bali"
        value={saleTagline}
        onChange={(e) => handleInputChange(e, setSaleTagline)}
        className={`mt-1.5 px-3 pt-2 pb-8 w-full bg-white rounded-md border min-h-[80px] ${
          errors.saleTagline ? 'border-red-500' : 'border-[#CBD5E1]'
        }`}
      />
      {errors.saleTagline && (
        <p className="text-red-500 text-xs mt-1">{errors.saleTagline}</p>
      )}
    </div>
  </section>
);

export default SaleDescriptionCard;
