import React from 'react';

import { DesktopLayout } from '../../../components/Layouts';
import { CreateSaleForm } from '../../../components/Form';

const Create: React.FC = () => {
  return (
    <DesktopLayout>
      <CreateSaleForm />
    </DesktopLayout>
  );
};

export default Create;
