import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { inAppWallet, preAuthenticate } from 'thirdweb/wallets/in-app';
import { useSetActiveWallet } from 'thirdweb/react';
import { polygonAmoy } from 'thirdweb/chains';
import { useActiveAccount } from 'thirdweb/react';

import { client } from '../../config/thirdwebConfig';
import { AuthLogoSection } from '../../components/Hero';
import { AuthForm } from '../../components/Form';
import { AuthButtons } from '../../components/Buttons';
import { OtpInput } from '../../components/OtpInput';

import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import useToken from '../../hooks/useToken';
import useModal from '../../hooks/useModal';

const Signup = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const wallet = inAppWallet();
  const setActiveAccount = useSetActiveWallet();
  const activeAccount = useActiveAccount();

  const {
    getUser,
    registerUser,
    admin: { login },
  } = useFantomStarterAdminApi();
  const { setToken } = useToken();

  const now = Date.now();

  const [isAlreadyUser, setIsAlreadyUser] = useState<boolean>(false);
  const [isSendingOtp, setIsSendingOtp] = useState<boolean>(false);
  const [address, setAddress] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const openOtpInputModal = useCallback((email: string) => {
    openModal(
      <OtpInput
        headerTitle="Sign Up"
        email={email}
        onClose={closeModal}
        onVerify={async (otpValue, setIsVerifyingOtp) => {
          try {
            setIsVerifyingOtp(true);
            await wallet.connect({
              client,
              chain: polygonAmoy,
              strategy: 'email',
              email,
              verificationCode: otpValue,
            });
            await setActiveAccount(wallet);
            closeModal();
          } catch (error) {
            console.error('Error while verifying OTP:', error);
          } finally {
            setIsVerifyingOtp(false);
          }
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = useCallback(async (email: string) => {
    try {
      setIsSendingOtp(true);
      await preAuthenticate({
        client,
        strategy: 'email',
        email,
      });
      setIsSendingOtp(false);
      openOtpInputModal(email);
    } catch (error) {
      console.error('Error while Pre authenticating:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinueWithGoogle = useCallback(async () => {
    try {
      await wallet.connect({
        client,
        chain: polygonAmoy,
        strategy: 'google',
      });
      await setActiveAccount(wallet);
    } catch (error) {
      console.error('Error while connecting with Google:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinueWithApple = useCallback(async () => {
    console.log('Continue with Apple');
    try {
      await wallet.connect({
        client,
        chain: polygonAmoy,
        strategy: 'google',
      });
      await setActiveAccount(wallet);
    } catch (error) {
      console.error('Error while continuing with Apple:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (address) {
      getUser(address).then((result) => {
        if (result?.data?.id) {
          setIsAlreadyUser(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (activeAccount && activeAccount.address) {
      setAddress(activeAccount.address);
      setIsConnected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount]);

  return (
    <main className="bg-white w-screen h-full sm:h-screen flex flex-col sm:flex-row items-center">
      <AuthLogoSection />
      {!isConnected && (
        <section className="w-full sm:w-2/5 p-6 flex flex-col items-center">
          <div className="flex flex-col items-center">
            <h1 className="text-2xl sm:text-3xl font-semibold">Sign Up</h1>
            <p className="text-lg sm:text-xl font-normal mt-2 text-center">
              Enter your email below to create your account
            </p>
          </div>
          <AuthForm
            onSubmit={handleFormSubmit}
            hideTnCBox
            isSubmitting={isSendingOtp}
          />
          <section className="flex gap-2 justify-center items-center w-full max-w-[360px] mx-auto mt-8 leading-none text-center text-[#334155]">
            <div className="flex-1 shrink self-stretch my-auto h-px border border-solid basis-0 border-[#E2E8F0]" />
            <div className="self-stretch my-auto text-sm sm:text-base">
              OR CONTINUE WITH
            </div>
            <div className="flex-1 shrink self-stretch my-auto h-px border border-solid basis-0 border-[#E2E8F0]" />
          </section>
          <AuthButtons
            onContinueWithGoogle={handleContinueWithGoogle}
            onContinueWithApple={handleContinueWithApple}
          />
          <section className="flex flex-row items-center gap-2 justify-center mt-5">
            <p className="text-sm sm:text-base text-[#334155]">
              Already have an account?
            </p>
            <button
              className="btn-text px-0 font-medium text-sm sm:text-base"
              onClick={() => navigate('/auth/login')}
            >
              Log in
            </button>
          </section>
        </section>
      )}
      {isConnected && address && activeAccount && (
        <section className="w-full sm:w-2/5 p-6 flex flex-col items-center">
          <div className="flex flex-col items-center">
            <h1 className="text-2xl sm:text-3xl font-semibold">Log In</h1>
            <p className="text-lg sm:text-xl text-center font-normal mt-2">
              Please sign the message request in your wallet to continue
            </p>
          </div>
          {isAlreadyUser ? (
            <button
              className="btn-primary w-full max-w-[360px] mx-auto mt-8"
              onClick={() =>
                activeAccount
                  ?.signMessage({
                    message: `FantomStarter-Admin-Login-${address}-${now.toString()}`,
                  })
                  .then((signature) =>
                    login(address, signature, now).then((result) => {
                      setToken(result?.data?.token);
                      navigate('/');
                    })
                  )
              }
            >
              Sign Transaction to Login
            </button>
          ) : (
            <button
              className="btn-primary w-full max-w-[360px] mx-auto mt-8"
              onClick={() =>
                registerUser(address).then((result) => {
                  if (result?.data?.status === 'created') {
                    setIsAlreadyUser(true);
                  }
                })
              }
            >
              Register as New User
            </button>
          )}
        </section>
      )}
    </main>
  );
};

export default Signup;
